import { environment } from "../environments";
import { axiosPrivate } from "api/axios";

export interface Enrichment {
  compound_id: string;
  label: string;
}

export const generateBotResponse = async (payload: 
  | {
      inputs: string | undefined | null;
      enrichment?: Enrichment;
      session_id: string | undefined | null;
      message_id: string | undefined | null;
    } 
  | FormData
) => {
  let config = {};

  if (payload instanceof FormData) {
    config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  const response = await axiosPrivate.post(
    `${environment.BACKEND_API}/llm/generate`,
    payload,
    config
  );
  return response.data;
};

export const deleteSession = async (session_id: string) => {
  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/delete_session/${session_id}`
  );
  return response.data;
};

export const deleteAllSession = async () => {
  const response = await axiosPrivate.delete(
    `${environment.BACKEND_API}/api/delete_all_sessions`
  );
  return response.data;
};

export const update_session = async (session_id: string, payload: object) => {
  const response = await axiosPrivate.put(
    `${environment.BACKEND_API}/api/update_session/${session_id}`,
    payload
  );
  return response.data;
};
