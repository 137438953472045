import { Text, View } from "@react-pdf/renderer";
import { SavedAssayProps } from "models/bookmarks/SavedProps";
import { tube } from "assets/bookmarks/reports/icons";
import { SectionHeader } from "./BookmarkToExport";

import { stringToColor } from "utils/tagsColors";

import {
  assaysCols,
  assaysStyles,
  documentStyles,
  tableStyles,
} from "./helpers";

interface Props {
  assays: SavedAssayProps[];
}

function AssaysToExport({ assays }: Props) {
  return (
    <>
      {assays && assays?.length > 0 && (
        <View style={documentStyles.section}>
          {/* header */}
          <SectionHeader src={tube} name={"Assays"} />

          {/* table */}
          <View style={tableStyles.container}>
            {/* Table Header */}
            <View style={tableStyles.header}>
              {assaysCols.map((col, index) => (
                <Text
                  key={`assays-table-header-${index}`}
                  style={[tableStyles.headerCell, { width: col.width }]}
                >
                  {col.name}
                </Text>
              ))}
            </View>

            {/* Table Body */}
            <View style={tableStyles.body}>
              {assays.map((assay, index) => {
                const indicationColor = stringToColor(
                  assay?.overview?.indication ?? ""
                );

                const assayTypes = assay?.overview?.assay_type
                  ?.split(",")
                  .map((type) => type.trim());

                return (
                  <View
                    key={`assay-row-${index}`}
                    style={tableStyles.row}
                    wrap={false}
                  >
                    {/* name */}
                    <Text
                      style={[
                        tableStyles.cell,
                        {
                          width: "40%",
                          lineHeight: 1.3,
                          fontSize: "11px",
                        },
                      ]}
                    >
                      {assay?.name}
                    </Text>

                    {/* id */}
                    <Text
                      style={[
                        tableStyles.cell,
                        { lineHeight: 1.3, fontSize: "10px" },
                      ]}
                    >
                      {assay?.assay_id}
                    </Text>

                    {/* indication */}
                    <View
                      style={[
                        tableStyles.cell,
                        {
                          width: "20%",
                          lineHeight: 1.3,
                          fontSize: "10px",
                        },
                      ]}
                    >
                      <View style={assaysStyles.flexRow}>
                        <View
                          style={[
                            {
                              backgroundColor: indicationColor,
                              borderRadius: "50%",
                              width: "10px",
                              height: "10px",
                            },
                          ]}
                        />
                        <Text
                          style={{
                            lineHeight: 1.3,
                            fontSize: "10px",
                            maxWidth: "90%",
                          }}
                        >
                          {assay?.overview?.indication}
                        </Text>
                      </View>
                    </View>

                    {/* types */}
                    <View
                      style={[
                        tableStyles.cell,
                        {
                          width: "30%",
                          lineHeight: 1.3,
                          fontSize: "10px",
                        },
                      ]}
                    >
                      {assayTypes?.map((type, i) => {
                        const typeColor = stringToColor(type);
                        return (
                          <View key={i} style={assaysStyles.flexRow}>
                            <View
                              style={[
                                {
                                  backgroundColor: typeColor,
                                  borderRadius: "50%",
                                  width: "10px",
                                  height: "10px",
                                },
                              ]}
                            />
                            <Text
                              style={{
                                lineHeight: 1.3,
                                fontSize: "10px",
                              }}
                            >
                              {type}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

export default AssaysToExport;
