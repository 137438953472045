import { Image, usePrefersReducedMotion } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import { illustration } from "assets/chatbot/newChatIllustration";

const fadeDown = keyframes`
  0% {
    transform: translateY(-30px) scale(0.7);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

function NewChatHeading() {
  const prefersReducedMotion = usePrefersReducedMotion();

  const fadeDownAnimation = prefersReducedMotion
    ? undefined
    : `${fadeDown} 1s ease-in-out`;

  return (
    <Image
      animation={fadeDownAnimation}
      src={illustration}
      loading={"lazy"}
      h={{ lg: "200px", xl: "300px" }}
      objectFit={"cover"}
      alt={"Hi, I'm Lakesai, your scientific research assistant!"}
    />
  );
}

export default NewChatHeading;
