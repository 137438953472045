import { Image, Text, View } from "@react-pdf/renderer";
import { graph } from "assets/bookmarks/reports/icons";
import { SectionHeader } from "./BookmarkToExport";

import { SavedCompoundProps } from "models/bookmarks/SavedProps";
import {
  adjustCompoundName,
  getAliases,
  getSmilesImageSrc,
} from "views/compounds/helpers";
import {
  documentStyles,
  moleculesCols,
  moleculeStyles,
  smileFallbackSrc,
  smileStyles,
  tableStyles,
  tagsStyles,
} from "./helpers";

interface Props {
  molecules: SavedCompoundProps[];
}

interface AliasesTagsProps {
  aliases: string[];
}

function MoleculesToExport({ molecules }: Props) {
  return (
    <>
      {molecules && molecules?.length > 0 && (
        <View style={documentStyles.section}>
          {/* header */}
          <SectionHeader src={graph} name={"Molecules"} />

          {/* table */}
          <View style={tableStyles.container}>
            {/* Table Header */}
            <View style={tableStyles.header}>
              {moleculesCols.map((col, index) => (
                <Text
                  key={`molecules-table-header-${index}`}
                  style={[tableStyles.headerCell, { width: col.width }]}
                >
                  {col.name}
                </Text>
              ))}
            </View>

            {/* Table Body */}
            <View style={tableStyles.body}>
              {molecules.map((molecule, index) => {
                const smilesImageSrc = getSmilesImageSrc(molecule);
                const src =
                  smilesImageSrc !== "ex.png"
                    ? smilesImageSrc
                    : smileFallbackSrc;

                return (
                  <View key={`molecule-row-${index}`} style={tableStyles.row}>
                    {/* molecule */}
                    <View style={smileStyles.container}>
                      <Image src={src} style={smileStyles.image} />
                    </View>
                    {/* id */}
                    <Text
                      style={[
                        tableStyles.cell,
                        {
                          width: "20%",
                          lineHeight: 1.3,
                          fontSize: "10px",
                        },
                      ]}
                    >
                      {molecule?.compound_id}
                    </Text>

                    {/* name */}
                    <View style={moleculeStyles.name}>
                      <Text
                        style={[
                          {
                            width: "100%",
                            lineHeight: 1.3,
                            fontSize: "10px",
                          },
                        ]}
                      >
                        {adjustCompoundName(molecule)}
                      </Text>
                    </View>

                    {/* aliases */}
                    <AliasesTags aliases={molecule?.aliases ?? []} />
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

export default MoleculesToExport;

function AliasesTags({ aliases }: AliasesTagsProps) {
  const processedAliases = getAliases(aliases);
  const moreTags = aliases ? aliases.length - processedAliases.length : 0;

  if (!processedAliases.length && moreTags) {
    return (
      <View style={tagsStyles.tag}>
        <Text>{aliases[0]}</Text>
      </View>
    );
  }

  return (
    <View style={tagsStyles.container}>
      {processedAliases.map((alias, index) => (
        <View key={"alias-" + index} style={tagsStyles.tag}>
          <Text>{alias}</Text>
        </View>
      ))}
      {!!moreTags && (
        <Text
          style={tagsStyles.moreTagsText}
        >{` and ${moreTags} more.. `}</Text>
      )}
    </View>
  );
}
