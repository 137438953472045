import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Flex, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";

import useWS from "hooks/chat/useWS";
import { ChatbotContext } from "views/chatbot";

import HumanMessage from "./HumanMessage";
import Loading from "components/ui/Loading";
import LoadingBubble from "./LoadingBubble";
import NewChatHeading from "./NewChatHeading";
import { suggestedQuestions } from "./helpers";
import SuggestedQuestion, { SuggestedQuestionProps } from "./SuggestedQuestion";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

function NewChatPanel() {
  // Contexts
  const {
    waitingOnBot,
    activeSession,
    loadingSessions,
    fileAttached,
    human_attachments,
    human_audio,
  } = useContext(ChatbotContext);
  const { questionOnWait }: { questionOnWait: string | undefined } =
    useOutletContext();

  // Hooks
  const { notifications, partialReply, cites } = useWS({ waitingOnBot });

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below

  const conversationHeight = useBreakpointValue({
    lg: "calc(100vh - 148px)", // 148 = (8x2) + 16 + 8 + 60 + (24x2): py + boxSize + gap + textfield + py
    xl: "calc(100vh - 156px)", // 156 = (12x2) + 16 + 8 + 60 + (24x2)
    "2xl": "calc(100vh - 164px)", // 164 = (16x2) + 16 + 8 + 60 + (24x2)
  });

  const listStyle = {
    height: conversationHeight,
    width: "100%",
    padding: "0 6px 0 0",
  };

  // true if user asks question in new chat
  const isActiveSession = useMemo(
    () => activeSession === "new",
    [activeSession]
  );

  // States
  const [scrolledUp, setScrolledUp] = useState(false);

  // Refs
  const messagesRef = useRef<HTMLDivElement>(null);
  const previousScrollTop = useRef(0); // Track previous scrollTop

  // Handlers
  function scrollToBottomWithBehavior(smoothScroll = true) {
    if (messagesRef.current) {
      const { scrollHeight } = messagesRef.current;

      smoothScroll
        ? messagesRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          })
        : (messagesRef.current.scrollTop = scrollHeight);
    }
  }

  function handleScrollUp() {
    if (waitingOnBot) {
      const currentScrollTop = messagesRef.current!.scrollTop;

      if (currentScrollTop < previousScrollTop.current) {
        // Remove event listener after first scroll up
        messagesRef.current!.removeEventListener("scroll", handleScrollUp);

        setScrolledUp(true);
      }

      // Update previous scrollTop value for next check
      if (previousScrollTop.current !== currentScrollTop) {
        previousScrollTop.current = currentScrollTop;
      }
    }
  }

  // scroll up event listener
  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef && !scrolledUp && !!waitingOnBot) {
      currentRef.addEventListener("scroll", handleScrollUp);
    } else return;

    return () => {
      if (currentRef && !scrolledUp && !!waitingOnBot) {
        currentRef.removeEventListener("scroll", handleScrollUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef, waitingOnBot]);

  // smooth scroll on streaming reply data
  useEffect(() => {
    const autoScrollEnabled = !scrolledUp && partialReply.length;

    if (autoScrollEnabled) {
      scrollToBottomWithBehavior(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialReply]);

  // smooth scroll on question submitted
  useEffect(() => {
    waitingOnBot ? scrollToBottomWithBehavior(true) : setScrolledUp(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingOnBot]);

  return (
    <>
      <Flex
        justify={"flex-end"}
        align={"flex-end"}
        h={`calc(100% - ${fileAttached ? "154px" : "100px"})`}
        w={{ lg: "700px", xl: "900px" }}
        maxW={"90%"}
        mx={"auto"}
        py={"24px"}
      >
        {/* welcoming content */}
        {!isActiveSession && !loadingSessions && <Welcome />}

        {/* transition phase - loading */}
        {!isActiveSession && loadingSessions && (
          <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
            <Loading />
          </Flex>
        )}

        {/* submit qst in new chat */}
        {!!waitingOnBot && isActiveSession && (
          <CustomScrollBar
            scrollableNodeProps={{ ref: messagesRef }}
            style={listStyle}
          >
            {/* temporary conversation thread: human(qst) + bot(notifications, partial reply) */}
            <Flex direction={"column"} px={2} pr={6} w={"100%"}>
              <Flex w="100%" mt={4} pl={"48px"}>
                <Flex
                  alignContent={"flex-start"}
                  width={"fit-content"}
                  display={"inline-block"}
                  ml={"auto"}
                >
                  {(!!questionOnWait ||
                    (!fileAttached && (human_attachments || human_audio))) && (
                    <HumanMessage questionOnWait={questionOnWait} />
                  )}
                </Flex>
              </Flex>
              <LoadingBubble
                cites={cites}
                partialReply={partialReply}
                notifications={notifications}
              />
            </Flex>
          </CustomScrollBar>
        )}
      </Flex>
    </>
  );
}

export default NewChatPanel;

function Welcome() {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"flex-end"}
      gap={{ lg: "20px", xl: "40px" }}
      w={"900px"}
      maxW={[null, null, null, "90%", "85%", "100%"]}
      mx={"auto"}
      p={{ lg: 2, xl: 3 }}
      h={"100%"}
    >
      {/* Heading with icon */}
      <Flex h={"100%"} align={"center"}>
        <NewChatHeading />
      </Flex>

      {/* suggested questions */}
      <SimpleGrid
        w={"100%"}
        mx={"auto"}
        templateColumns={[
          null,
          null,
          null,
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        templateRows={[
          null,
          null,
          null,
          "repeat(4, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        spacing={[null, null, null, "8px", "16px", "18px"]}
      >
        {suggestedQuestions.map(
          (
            item: Omit<SuggestedQuestionProps, "onSendSuggestedQuestion">,
            index: number
          ) => (
            <SuggestedQuestion
              key={index}
              question={item.question}
              header={item.header}
              onSendSuggestedQuestion={handleSendQuestion}
            />
          )
        )}
      </SimpleGrid>
    </Flex>
  );
}
