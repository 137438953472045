import {
    ChangeEvent,
    KeyboardEvent,
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
    selectCurrentSavedData,
    setSuccess,
} from "redux/features/bookmarks/savedSlice";

import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Text,
    Textarea,
    useBreakpointValue,
    useDisclosure,
    useTheme,
    useToast,
} from "@chakra-ui/react";

import { environment } from "environments";
import { errorHandler, hexToRgba } from "utils/helpers";

import { SavedFolderProps } from "models/bookmarks/SavedProps";
import { selectCurrentPostsData, setTeamData } from "redux/features/posts/postsSlice";
import { useTeamsAPI } from "api/useTeamsAPI";
import { useQuery } from "@tanstack/react-query";
import MembersTable from "./MembersTable";
import Loading from "components/ui/Loading";

interface FolderAccessListProps {
    folder: SavedFolderProps | undefined;
    isOpen: boolean;
    onClose: () => void;
}

function FolderAccessList({ folder, isOpen, onClose }: FolderAccessListProps) {
    const toast = useToast();
    const { colors } = useTheme();
    const { folderId } = useParams();
    const dispatch = useDispatch();
    
    const { teamData } = useSelector(selectCurrentPostsData);

    const { getTeam } = useTeamsAPI();

    const [isLoading, setIsLoading] = useState<any>(null);    

    const drawerRef: MutableRefObject<HTMLElement | null> = useRef(null);
    
    const getTeamData = async () => {        
        await getTeam().then(response =>{
            dispatch(setTeamData(response));
            setIsLoading(false);
        }).catch(error =>{            
            setIsLoading(false);
        });
    }    
    
    useEffect(() => {
        if(isLoading==null && teamData == null){            
            setIsLoading(true);
            getTeamData();
        }
        else if (isLoading == null && teamData != null){
            setIsLoading(false);
        }
    }, [isLoading, teamData]);

    return (
        <>
            {folder ? (
                <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    finalFocusRef={drawerRef}
                >
                    <DrawerOverlay
                        backdropFilter={"blur(3.5px)"}
                        bg={"rgba(0,0,0,0.1)"}
                    />

                    <DrawerContent
                        bg={"background"}
                        h={"80vh"}
                        w={"90vw"}
                        borderRadius={"12px 0 0 12px"}
                        p={0}
                        alignSelf={"center"}
                        borderColor={"gray.100"}
                        borderWidth={1}
                        boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
                    >
                        <DrawerCloseButton size={"sm"} color={"gray.400"} />

                        <DrawerHeader
                            p={3}
                            borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
                            borderBottomWidth={1}
                        >
                            <Text fontSize={"14px"} color={"gray.600"}>
                                Folder Access List
                            </Text>
                        </DrawerHeader>

                        <DrawerBody p={3} display={"flex"} flexDir={"column"} gap={4} width={"100%"}>
                            {(isLoading != false) ?
                                <Flex
                                    h={"100%"}
                                    w={"100%"}
                                    align={"center"}
                                    justify={"center"}
                                    color={"gray.500"}
                                    direction={"column"}
                                >
                                    <Loading message="Loading ..." />
                                </Flex>
                                :
                                <>
                                    {
                                        (teamData != null && teamData?.id?.length > 0) ?
                                            <MembersTable teamData={teamData} folderId={folderId}></MembersTable>
                                            :
                                            <Flex
                                                h={"100%"}
                                                w={"100%"}
                                                align={"center"}
                                                justify={"center"}
                                                color={"gray.500"}
                                                direction={"column"}
                                            >
                                                <Text color={"gray.500"} textAlign={"center"}>You are not part of any team to share this folder.</Text>
                                            </Flex>
                                    }
                                </>
                            }
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            ) : (
                <></>
            )}
        </>
    );
}

export default FolderAccessList;
