import React, { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Image,
  Flex,
  Link,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "redux/features/auth/authSlice";
import { environment } from "environments";

import googleImg from "assets/login/google.svg";
import { hexToRgba } from "utils/helpers";

export default function Login({
  setResetPasswordSession,
  setExpired,
  setFormData,
  setChangePassword,
  formData,
}: {
  setResetPasswordSession: (val: string | null) => void;
  setExpired: (val: boolean) => void;
  setChangePassword: (val: boolean) => void;
  setFormData: (data: { username: string; password: string }) => void;
  formData: {
    username: string;
    password: string;
  };
}) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Handlers
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const { username, password } = formData;

      if (!username || !password) return;

      setSubmitting(true);
      const response = await axiosClient.post(
        "/api/auth",
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { user, accessToken, refreshToken } = response?.data;
      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));
    } catch (error: any) {
      // No response from server
      console.log("error: ", error);
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      if (error.response.data.message === "NEW_PASSWORD_REQUIRED") {
        toast({
          description: "Please reset your password",
          status: "info",
          position: "top-right",
        });

        setResetPasswordSession(error.response.data.session);
        setChangePassword(true);
      } else if (error.response.data.message === "PASSWORD_EXPIRED") {
        setExpired(true);
        toast({
          description: "Your temporary password has expired, get new one",
          status: "info",
          position: "top-right",
        });
      } else if (error.response.data.message === "USER_DISABLED") {
        toast({
          description:
            "Access revoked for this user. Contact admin for account activation",
          status: "error",
          position: "bottom",
        });
      } else {
        toast({
          description: error.response.data.message,
          status: "error",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const thirdPartySignIn = () => {
    setRedirecting(true);
    const redirectUri = encodeURIComponent(environment.AUTH_REDIRECT_URI);
    const OAUTH2_URL = `${environment.AUTH_DOMAIN}/oauth2/authorize?client_id=${environment.APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUri}&prompt=select_account`;
    window.open(OAUTH2_URL, "_self");
  };

  return (
    <Flex direction={"column"} gap={4} w="100%" maxW="460px">
      <Flex direction={"column"} gap={4} w={"100%"}>
        <FormControl id={"username"} w={"100%"}>
          <FormLabel
            htmlFor={"username"}
            color={"secondary.700"}
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={1}
            mb={1}
          >
            Username
          </FormLabel>
          <Input
            w={"100%"}
            color={"gray.600"}
            fontSize={"14px"}
            type={"text"}
            name={"username"}
            value={formData.username}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={"Enter your username"}
            _placeholder={{ fontSize: "14px", color: "gray.600" }}
            borderRadius={"6px"}
            bg={"background"}
            borderWidth={1}
            borderColor={"gray.200"}
            _hover={{ borderColor: "gray.300" }}
            _focusVisible={{ borderColor: "blue.300" }}
          />
        </FormControl>

        <FormControl id="password" w={"100%"}>
          <FormLabel
            htmlFor="password"
            color={"secondary.700"}
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={1}
            mb={1}
          >
            Password{" "}
          </FormLabel>
          <InputGroup>
            <Input
              w={"100%"}
              color={"gray.600"}
              fontSize={"14px"}
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter your password"
              _placeholder={{ fontSize: "14px", color: "gray.600" }}
              borderRadius={"6px"}
              bg={"background"}
              borderWidth={1}
              borderColor={"gray.200"}
              _hover={{ borderColor: "gray.300" }}
              _focusVisible={{ borderColor: "blue.300" }}
            />
            <InputRightElement>
              <Icon
                boxSize={"14px"}
                as={showPassword ? ViewOffIcon : ViewIcon}
                color={"secondary.300"}
                cursor={"pointer"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Link
          color={"primary.400"}
          fontSize={"12px"}
          lineHeight={"1"}
          href="/reset-password"
          ml={"auto"}
        >
          Forgot password?
        </Link>
      </Flex>

      <Flex direction={"column"} align={"center"} gap={4}>
        <Button
          mt={4}
          py={"10px"}
          w={"100%"}
          h={"fit-content"}
          size={"sm"}
          type={"submit"}
          bg={"primary.400"}
          color={"primary.100"}
          onClick={handleSubmit}
          isLoading={submitting}
          isDisabled={redirecting}
          loadingText={"Processing"}
          _hover={{ bg: "primary.500" }}
        >
          Login
        </Button>

        <Flex
          direction={"column"}
          align={"center"}
          textAlign={"center"}
          gap={1}
        >
          <Text fontSize={"14px"} color={"gray.600"}>
            Or Connect with:
          </Text>

          <Flex
            justify={"center"}
            align={"center"}
            p={3}
            boxShadow={
              "rgba(0,193,180, .4) 0px 2px 5px -1px , rgba(0,193,180, .3) 0px 1px 3px -1px"
            }
            borderRadius={"10px"}
            pointerEvents={redirecting || submitting ? "none" : "auto"}
            cursor={redirecting || submitting ? "not-allowed" : "pointer"}
            transition={"all ease 0.3s"}
            _hover={{ bg: hexToRgba("#00C1B4", 0.2) }}
            onClick={() => thirdPartySignIn()}
          >
            {redirecting ? (
              <Spinner w={"30px"} h={"30px"} />
            ) : (
              <Image src={googleImg} alt="Google" boxSize={"30px"} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
