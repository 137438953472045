import { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { Flex, useTheme } from "@chakra-ui/react";

import AdditionalPapersPanel from "./AdditionalPapersPanel";

import { getChartYears, getPaperColor } from "views/compounds/helpers";
import { stylesToString } from "utils/helpers";
import { AuthorProps, PaperProps } from "models/papers/PaperProps";

interface PapersChartTooltipProps {
  color: string;
  value: number;
  seriesName: string;
  data: { value: number; groupedByType: PaperProps[] };
}

export interface PapersReferencesChartProps {
  papersData: PaperProps[];
}

export interface ExtraPapersProps {
  title?: string;
  id?: string;
}

export default function PapersReferencesChart({
  papersData,
}: PapersReferencesChartProps) {
  // Theme
  const { colors } = useTheme();

  // assign all others types (including falsy values) to "Unclassified"
  const cleanedPapersData = useMemo(() => {
    return papersData?.map((paper) => ({
      ...paper,
      paper_type:
        paper?.paper_type && paper?.paper_type.toLowerCase() !== "neither"
          ? paper?.paper_type
          : "Unclassified",
    }));
  }, [papersData]);

  // list of valid papers types (valid year_published) for this compound
  const papersTypes = useMemo(
    () => [
      ...new Set(
        cleanedPapersData
          ?.filter((paper: PaperProps) => !!paper.year_published)
          .map((paper: PaperProps) => paper.paper_type)
      ),
    ],
    [cleanedPapersData]
  );

  // sort additional papers titles
  const additionalPapersTitles: (ExtraPapersProps | undefined)[] = (
    cleanedPapersData || []
  )
    .filter((paper: PaperProps) => !paper.year_published)
    .map((paper: PaperProps) => ({
      title: paper?.title,
      id: paper?.id,
    }))
    .sort((a: PaperProps, b: PaperProps) => {
      const a_title: string | undefined = a?.title;
      const b_title: string | undefined = b?.title;

      if (a_title && b_title) {
        return a_title.localeCompare(b_title);
      }
      return 1;
    });

  const yearsList: number[] = useMemo(
    () => getChartYears(cleanedPapersData),
    [cleanedPapersData]
  );

  const tooltip = useMemo(() => {
    return {
      trigger: "item",
      enterable: true,
      transitionDuration: 1,
      formatter: function ({
        color,
        value,
        seriesName,
        data,
      }: PapersChartTooltipProps) {
        const { groupedByType } = data;

        const wrapper = {
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          "text-align": "left",
          padding: "0px",
          "min-width": "240px",
          "max-width": "300px",
        };
        const wrapperStyle = stylesToString(wrapper);

        const header = {
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center",
          "margin-bottom": "8px",
        };
        const headerStyle = stylesToString(header);

        const tag = {
          "font-size": "10px",
          "font-transform": "uppercase",
          "line-height": "1.3",
          width: "fit-content",
          color: "white",
          "background-color": color,
          "border-radius": "3px",
          padding: "2px 4px",
        };
        const tagStyle = stylesToString(tag);

        const count = {
          "font-size": "10px",
          "font-weight": "bold",
          "line-height": "1.3",
          width: "fit-content",
          color: color,
          border: `1px solid ${color}`,
          "border-radius": "3px",
          padding: "0 4px",
        };
        const countStyle = stylesToString(count);

        const container = {
          width: "100%",
          display: "flex",
          "flex-direction": "column",
          "max-height": "300px",
          padding: "4px",
          "overflow-y": "auto",
        };
        const containerStyle = stylesToString(container);

        const paper = {
          display: "flex",
          "flex-direction": "column",
          width: "100%",
          gap: "4px",
          "border-bottom": `1px solid rgba(0, 0, 0, 0.1)`,
          padding: "12px 0",
        };
        const paperStyle = stylesToString(paper);

        const lastPaper = {
          display: "flex",
          "flex-direction": "column",
          width: "100%",
          gap: "4px",
          "border-bottom": "none",
          padding: "8px 0",
        };
        const lastPaperStyle = stylesToString(lastPaper);

        const title = {
          display: "block",
          "font-weight": "500",
          "font-size": "13px",
          "line-height": "1.3",
          "white-space": "normal",
          "text-align": "left",
          width: "95%",
          color: color,
          cursor: "pointer",
        };
        const titleStyle = stylesToString(title);

        const item = {
          "font-size": "11px",
          color: colors.gray[500],
        };
        const itemStyle = stylesToString(item);

        const label = {
          "font-size": "11px",
          "font-weight": "bold",
        };
        const labelStyle = stylesToString(label);

        const icon = {
          "font-size": "13px",
          "font-weight": "bold",
        };
        const iconStyle = stylesToString(icon);

        const authors = {
          "font-size": "10px",
          color: colors.gray[500],
          "line-height": "16px",
          "white-space": "nowrap",
          overflow: "hidden",
          "text-overflow": "ellipsis",
        };
        const authorsStyle = stylesToString(authors);

        const getAuthorName = (idx: number, auth: AuthorProps) =>
          `<p key="${idx}" style="${authorsStyle}">
            ✦ ${auth?.first_name} ${auth?.last_name}
          </p>`;

        const containerContent = groupedByType
          .map((p: PaperProps, idx: number) => {
            const hasAuthors = p?.authors && p?.authors?.length > 0;
            const pStyle =
              idx === groupedByType.length - 1 ? lastPaperStyle : paperStyle;

            const authorsContent =
              hasAuthors &&
              `<span style="${itemStyle}"><span style="${labelStyle}">Authors:</span>
                ${p?.authors
                  ?.map((auth: AuthorProps, idx: number) =>
                    getAuthorName(idx, auth)
                  )
                  .join("")}
              </span>`;

            const citationsContent = `
              <Flex style="${itemStyle}"><span style="${labelStyle}">Citation:</span> ${p?.scholarly_citations_count}</Flex>
              `;

            return `<Flex key="${idx}" style="${pStyle}">
                  <a href="/data/core/MoleculeLake/data/literature/${
                    p?.id
                  }" target="_blank" style="${titleStyle}">${
              p?.title
            }<span style="${iconStyle}">➜</span></a>
                  ${hasAuthors ? authorsContent : ""}
                  ${citationsContent}</Flex>`;
          })
          .join("");

        const headerContent = [
          `<span style="${tagStyle}">${seriesName}</span>`,
          `<span style="${countStyle}">${value}</span>`,
        ].join("");

        const tooltipContent = [
          `<span style="${headerStyle}">${headerContent}</span>`,
          `<span style="${containerStyle}">${containerContent}</span>`,
        ].join("");

        return `<Flex style="${wrapperStyle}">${tooltipContent}</Flex>`;
      },
      confine: true,
    };
  }, [colors]);

  const option = {
    tooltip,
    xAxis: {
      data: yearsList,
      name: "Years",
      nameTextStyle: {
        color: colors.gray[300],
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        alignWithLabel: true,
        lineStyle: {
          color: colors.gray[300],
        },
      },
      axisLabel: {
        rotate: 45,
        margin: 12,
        color: colors.gray[300],
      },
    },
    yAxis: {
      type: "value",
      name: "Papers",
      nameTextStyle: {
        color: colors.gray[300],
      },
      minInterval: 1,
      axisLine: {
        show: false,
      },
      axisTick: {
        alignWithLabel: true,
        lineStyle: {
          color: colors.gray[300],
        },
      },
      axisLabel: {
        color: colors.gray[300],
      },
    },
    legend: {
      show: true,
      data: [...papersTypes],
      top: "top",
      left: "center",
      right: "center",
      width: "80%",
      icon: "circle",
      itemHeight: 12,
      itemWidth: 12,
      textStyle: {
        fontSize: 11,
        color: "source",
      },
    },

    grid: {
      containLabel: true,
      show: false,
      left: "3%",
      right: "6%",
      top: 50,
      bottom: 5,
    },
    series: papersTypes.map((type: string | undefined) => ({
      name: type,
      type: "bar",
      barMaxWidth: 10,
      stack: "references",
      emphasis: {
        focus: "self",
        itemStyle: {
          color: getPaperColor(type?.toLowerCase()),
        },
      },
      data: yearsList.map((year: number) => {
        const currentData: PaperProps[] = cleanedPapersData.filter(
          (paper: PaperProps) =>
            paper.paper_type === type && year === paper.year_published
        );
        return {
          value: currentData.length,
          groupedByType: currentData,
        };
      }),
      itemStyle: {
        color: getPaperColor(type?.toLowerCase()),
      },
    })),
  };

  const hasExtraPapers: boolean = useMemo(
    () => additionalPapersTitles && additionalPapersTitles.length > 0,
    [additionalPapersTitles]
  );

  const hasOnlyExtraPapers: boolean = useMemo(
    () =>
      hasExtraPapers &&
      additionalPapersTitles.length === cleanedPapersData.length,
    [hasExtraPapers, additionalPapersTitles, cleanedPapersData]
  );

  const stackedChartStyle = {
    minHeight: "100%",
    height: "420px",
    width: `${hasExtraPapers ? "70%" : "100%"}`,
    margin: "0 auto",
  };

  return (
    <Flex gap={3}>
      {/* hide chart when all papers are extra papers */}
      {!hasOnlyExtraPapers && (
        <ReactEcharts option={option} style={stackedChartStyle} />
      )}

      {/* show additional papers panel when extra papers data found */}
      <AdditionalPapersPanel
        titles={additionalPapersTitles}
        data={cleanedPapersData}
        showPanel={hasExtraPapers}
        showChart={!hasOnlyExtraPapers}
      />
    </Flex>
  );
}
