import {
  Button,
  Checkbox,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useTeamsAPI } from "api/useTeamsAPI";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import {
  MemberProps,
  SelectedUsersProps,
  TeamProps,
} from "models/posts/PostProps";
import { useEffect, useState } from "react";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

interface MembersTableProps {
  teamData: TeamProps;
  folderId: string | undefined;
}

const tableStyle = {
  height: "94%",
  width: "100%",
  padding: "0",
};

const MembersTable = ({ teamData, folderId }: MembersTableProps) => {
  const toast = useToast();

  const headers = ["Name", "Permission"];
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsersProps[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { updateSavedElementsAccessList, fetchSavedElementsAccessList } =
    useTeamsAPI();

  const { user } = useSelector(selectCurrentAuthData);

  const updateSelectedUsers = (
    user_id: string,
    value: string,
    option: string
  ) => {
    let tempSelectedUsers = [...selectedUsers];

    const index = tempSelectedUsers.findIndex(
      (x: SelectedUsersProps) => x?.user_id === user_id
    );

    if (option === "checkbox" && value === "true") {
      tempSelectedUsers.push({ user_id: user_id, permission: "" });
    } else if (option === "checkbox" && value === "false") {
      tempSelectedUsers.splice(index, 1);
    } else if (option === "select") {
      if (index < 0 && value?.length > 0) {
        tempSelectedUsers.push({ user_id: user_id, permission: value });
      } else if (value?.length > 0) {
        tempSelectedUsers[index].permission = value;
      } else {
        tempSelectedUsers.splice(index, 1);
      }
    }

    setSelectedUsers(tempSelectedUsers);
  };

  const checkIfSelected = (userId: string) => {
    return (
      selectedUsers.findIndex(
        (x: SelectedUsersProps) => x?.user_id === userId
      ) >= 0
    );
  };

  const getPermissionValue = (userId: string) => {
    return selectedUsers.find((x: SelectedUsersProps) => x?.user_id === userId)
      ?.permission;
  };

  const submitShareList = async () => {
    for (let member of selectedUsers) {
      if (member.permission?.length === 0) {
        toast({
          description:
            "You have selected a member but have not selected the folder permission",
          status: "warning",
          position: "top-right",
        });
        return;
      }
    }
    setIsSaving(true);
    await updateSavedElementsAccessList(selectedUsers, folderId)
      .then((response) => {
        toast({
          description: "Successfully saved the folder's access list",
          status: "success",
          position: "top-right",
        });
      })
      .catch((error) => {
        toast({
          description: errorHandler(error).message,
          status: "error",
          position: "top-right",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const renderHeader = (text: string, index: number) => {
    return (
      <Th
        key={"table-header" + index}
        py={2}
        px={2}
        textAlign={"left"}
        position="sticky"
        top={0}
        zIndex={1}
        bg="gray.100"
        borderLeftRadius={index === 0 ? "md" : "none"}
        borderRightRadius={index === headers.length - 1 ? "md" : "none"}
        borderRight={index !== headers.length - 1 ? "1px solid" : "none"}
        borderRightColor={"gray.200"}
      >
        {text}
      </Th>
    );
  };

  const renderRow = (user: MemberProps, index: number) => {
    return (
      <Tr
        key={"table-row" + index}
        py={5}
        transition="background 0.3s ease"
        _hover={{ cursor: "pointer", bg: "gray.100" }}
      >
        <Td
          key={"given_name" + user.given_name + user.family_name + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          <Checkbox
            mr={2}
            colorScheme="primary"
            defaultChecked={checkIfSelected(user.id)}
            onChange={(event: any) =>
              updateSelectedUsers(
                user.id,
                event.target.checked.toString(),
                "checkbox"
              )
            }
          />{" "}
          {user.given_name} {user.family_name}
        </Td>
        <Td
          key={"permission" + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          <Select
            value={getPermissionValue(user.id)}
            isDisabled={!checkIfSelected(user.id)}
            placeholder={"Select permission"}
            sx={{
              option: { backgroundColor: "background", color: "gray.600" },
            }}
            onChange={(event: any) =>
              updateSelectedUsers(user.id, event.target.value, "select")
            }
          >
            <option value="read">Read</option>
            <option value="write">Write</option>
          </Select>
        </Td>
      </Tr>
    );
  };

  const fetchAcessList = async () => {
    await fetchSavedElementsAccessList(folderId)
      .then((response) => {
        setSelectedUsers(response);
      })
      .catch((error) => {
        toast({
          description: errorHandler(error).message,
          status: "error",
          position: "top-right",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isLoading === true) {
      fetchAcessList();
    }
  }, [isLoading]);

  return (
    <TableContainer height="88%" style={{ position: "relative" }}>
      {isLoading ? (
        <Flex
          h={"100%"}
          w={"100%"}
          align={"center"}
          justify={"center"}
          color={"gray.500"}
          direction={"column"}
        >
          <Loading message="Loading team members ..." />
        </Flex>
      ) : (
        <>
          <CustomScrollBar style={tableStyle}>
            <Button
              colorScheme="secondary"
              size="sm"
              style={{ float: "right" }}
              onClick={() => submitShareList()}
              isLoading={isSaving}
              mb={1}
            >
              Save
            </Button>
            <Table variant="simple" size="sm">
              {teamData.members?.length > 0 ? (
                <>
                  <Thead>
                    <Tr>
                      {headers.map((text, index) => renderHeader(text, index))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {teamData.members?.map((row: any, index: number) =>
                      row.user.id != user.id ? (
                        renderRow(row.user, index)
                      ) : (
                        <></>
                      )
                    )}
                  </Tbody>
                </>
              ) : (
                <Text color={"gray.500"} marginTop={20} textAlign={"center"}>
                  There are no team members to share the folder.
                </Text>
              )}
            </Table>
          </CustomScrollBar>
        </>
      )}
    </TableContainer>
  );
};

export default MembersTable;
