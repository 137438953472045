import React, { ReactNode, ChangeEvent, DragEvent } from 'react';
import { InputGroup, Button, ButtonProps, useTheme, Input, FormErrorMessage, FormControl, Text, Box } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi'; // Import your icon library as needed

type SourceUploadProps = {
  dragActive?: boolean;
  valid?: boolean;
  accept?: string;
  multiple?: boolean;
  children?: ReactNode; // Ensure children prop is correctly typed
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  error?: string;
  handleDrag?: (e: DragEvent) => void;
  handleDrop?: (e: DragEvent) => void;
};

const SourceUpload = ({
  dragActive,
  valid,
  accept,
  multiple,
  children,
  handleFileChange,
  onButtonClick,
  handleDrop,
  error,
  handleDrag,
}: SourceUploadProps) => {
  const { colors } = useTheme();

  return (
    <form id="form-file-upload">
      <Box
        backgroundColor={'background'}
        p="40px"
        justifyContent="center"
        textAlign="center"
        w="100%"
        my="14px"
        borderRadius="10px"
        borderStyle="dotted"
        borderColor={colors.gray[300]}
        borderWidth={2}
        position={dragActive ? 'absolute' : 'relative'}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <Text fontSize="16px" fontWeight="500" color={colors.gray[500]}>Drop your files here</Text>
        <Text fontSize="16px" color="highlight.primary">or</Text>
        <FormControl isInvalid={valid} isRequired id="uploadFile" w="100%" mt="14px" textAlign={'center'}>
          <InputGroup id="file-upload" justifyContent={'center'} width={'fit-content'} justifyItems={'center'} margin={'0 auto'}>
            <Input
              type="file"
              name="file-uploader-field"
              id="file-uploader-field"
              multiple={multiple || false}
              accept={accept}
              hidden={false}
              onChange={handleFileChange}
              style={{
                width: "100%",
                height: '50px',
                opacity: 0,
                position: 'absolute',
                top: 0,
                zIndex: 1,
                cursor: 'pointer',
              }}
            />
            <Button
              zIndex={0}
              backgroundColor='highlight.primary'
              onClick={onButtonClick}
              leftIcon={<FiUpload color={colors.white} />}
            >
              {children}
            </Button>
          </InputGroup>
          <Text textAlign={'center'} alignSelf={'center'} color={'red.500'} mt={'20px'} fontSize={'14px'}>{error}</Text>
          {!error &&
          <Text textAlign={'center'} alignSelf={'center'} color={'gray.400'} mt={'20px'} fontSize={'14px'}>Max 10 files allowed per upload and up to 1GB for all files!</Text>
          }
        </FormControl>
      </Box>
    </form>
  );
};

export default SourceUpload;
