import { useState } from "react";
import { Flex } from "@chakra-ui/react";

import HelpTourButton from "./helpTour/HelpTourButton";
import FeedbackModal from "./feedback/FeedbackModal";
import FeedbackButton from "./feedback/FeedbackButton";

function HelpButtons() {
  // States
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [hasFeedbackError, setHasFeedbackError] = useState(false);

  return (
    <>
      <Flex
        display={"flex"}
        gap={2}
        align={"center"}
        w={"175px"}
        position={"absolute"}
        top={"50%"}
        right={"-69px"}
        transform={"translateY(calc(-50% - 70px))  rotate(-90deg)"}
        zIndex={1350}
      >
        {/* Tour button */}
        <HelpTourButton />

        {/* Feedback button */}
        <FeedbackButton
          handleClick={() => {
            setShowFeedbackModal(true);
            setFeedbackSent(false);
          }}
        />
      </Flex>

      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => {
          setShowFeedbackModal(false);
          setHasFeedbackError(false);
        }}
        sent={feedbackSent}
        setSent={setFeedbackSent}
        hasError={hasFeedbackError}
        setHasError={setHasFeedbackError}
      />
    </>
  );
}

export default HelpButtons;
