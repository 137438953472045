import {
  Box,
  Text,
  SimpleGrid,
  Card,
  Heading,
  CardBody,
  Icon,
  Circle,
  Flex,
  useTheme,
  useBreakpointValue,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import {
  coreSources,
  MRNASources,
  proprietarySources,
  SourceProps,
} from "../helpers";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import ToolsSection from "components/data/core/layers/tools/ToolsSection";
import { IoIosArrowForward } from "react-icons/io";

interface SourcesSectionProps {
  sources: SourceProps[];
  layerName: string;
  onOpen: (p: string) => void;
}

export default function SourcesList() {
  // Hooks
  const navigate = useNavigate();
  const { layerName } = useParams();

  // Responsiveness
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  // Style
  const listStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 6px 0",
  };

  function openSource(path: string) {
    navigate(`/data/core/${layerName}/data/${path.toLowerCase()}`);
  }

  const isMoleculeLake = layerName === "MoleculeLake";
  const isMRNALake = layerName === "mRNALake";
  const isPrivate = layerName === "Private";
  let sources: SourceProps[] = [];

  if (isMoleculeLake) sources = coreSources;
  else if (isMRNALake) sources = MRNASources;
  else if (isPrivate) sources = proprietarySources;

  console.log(sources, 'sources')
  return (
    <CustomScrollBar style={listStyle}>
      <Flex direction={"column"} gap={"60px"}>
        <SourcesSection
          sources={sources}
          layerName={layerName ?? ""}
          onOpen={openSource}
        />
        {isMRNALake && <ToolsSection />}
      </Flex>
    </CustomScrollBar>
  );
}

function SourcesSection({ sources, layerName, onOpen }: SourcesSectionProps) {
  // Theme
  const { colors } = useTheme();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgCard = isLight ? "background" : `lightBackground`;

  return (
    <Flex direction={"column"} gap={2}>
      <Text
        fontSize={"20px"}
        fontWeight={"500"}
        lineHeight={1}
        bgGradient={`linear(to-l, ${colors.highlight.primary} 0%, ${colors.secondary[600]} 100%)`}
        bgClip={"text"}
        color={"transparent"}
        w={"fit-content"}
        pl={2}
      >
        Data
      </Text>

      <SimpleGrid columns={2} spacing={6} p={1}>
        {sources.map((source, index) => (
          <Box key={"core-source-" + index}>
            <Card
              bg={bgCard}
              h={"120px"}
              _hover={{
                bg: hexToRgba(colors.secondary[100], 0.15),
                cursor: "pointer",
              }}
              onClick={() => onOpen(source.path)}
            >
              <CardBody>
                <Flex gap={6} align={"center"}>
                  <Circle
                    size="50px"
                    bg={source.color}
                    color="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon as={source.icon} />
                  </Circle>

                  <Box>
                    <Heading size="sm" textTransform={"capitalize"}>
                      {layerName === "Private" ? "LITERATURES" : source.key}
                    </Heading>
                    <Text
                      fontSize={"sm"}
                      maxH="3em"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {source.description}
                    </Text>
                    {source.source && (
                      <Text fontSize={"xs"} color={"neutral.800"} mt={2}>
                        By {source.source}
                      </Text>
                    )}
                  </Box>
                </Flex>
              </CardBody>
            </Card>
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
}
