import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import Loading from "components/ui/Loading";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TeamMemberActivityProps, TeamMessageProps } from "models/posts/PostProps";
import {
  selectCurrentPostsData,
} from "redux/features/posts/postsSlice";
import { errorHandler } from "utils/helpers";
import { useTeamsAPI } from "api/useTeamsAPI";
import ActivityTable from "components/team/members/ActivityTable";

function ActivityPanel() {
  const toast = useToast();

  const { teamData } = useSelector(selectCurrentPostsData);

  const { fetchTeamMembersActivity } = useTeamsAPI();

  const [isLoading, setIsLoading] = useState<any>(null);
  const [teamActivityData, setTeamActivityData] = useState<TeamMemberActivityProps[]>([]);

  const loadTeamActivityData = async () => {
    await fetchTeamMembersActivity(teamData.id).then(response => {
      setTeamActivityData(response);
    }).catch(error => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (teamData?.id && teamActivityData.length == 0 && isLoading == null) {
      setIsLoading(true);
      loadTeamActivityData();
    }
  }, [teamData]);


  return (
    <Box w={"100%"}>
      {isLoading != false ?
        <Flex
          h={"100%"}
          w={"100%"}
          align={"center"}
          justify={"center"}
          color={"gray.500"}
          direction={"column"}
        >
          <Loading message="Loading team activity data ..." />
        </Flex>
        :
        <ActivityTable teamActivities={teamActivityData} />
      }
    </Box>
  );
}

export default ActivityPanel;
