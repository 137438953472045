import { ChangeEvent, useState } from "react";
import { useStripeAPI } from "services/stripe.service";

import {
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
  useToast,
} from "@chakra-ui/react";

import Lmic from "./Lmic";
import Institution from "./Institution";
import VisitorName from "./VisitorName";
import VisitorEmail from "./VisitorEmail";
import SuccessToast from "./SuccessToast";
import VisitorMessage from "./VisitorMessage";

import {
  checkFormDataValidation,
  EnterpriseProps,
  ErrorsProps,
  FormDataProps,
} from "views/home/helpers";

import { MdOutlineMarkEmailRead } from "react-icons/md";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function VisitorEntrepriseRequest({ isOpen, onClose }: Props) {
  // States
  const [institutName, setInstitutName] = useState(""); // Team name
  const [isLmic, setIsLmic] = useState("no");
  const [country, setCountry] = useState("");
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState<ErrorsProps>({
    name: false,
    email: false,
    message: false,
  });

  // Hooks
  const toast = useToast();

  // APIs
  const { requestTeamsPackage } = useStripeAPI();

  // Handlers
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedName = event.target.value;
    setFormData((prev) => ({ ...prev, name: updatedName }));
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedEmail = event.target.value;
    setFormData((prev) => ({ ...prev, email: updatedEmail }));
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedMessage = event.target.value;
    setFormData((prev) => ({ ...prev, message: updatedMessage }));
  };

  async function sendRequest(e: any) {
    e.preventDefault();

    const isValidFormData = checkFormDataValidation(formData, setErrors);
    const isNotValidLmicData = isLmic === "yes" && country === "";

    // if form data is invalid, don't send
    if (!isValidFormData || isNotValidLmicData) return;

    try {
      setSending(true);

      // TODO: send Mail to lakesai mailbox..
      const payload: EnterpriseProps = {
        name: institutName,
        members: 2,
        email: formData.email,
        is_yearly: false,
        username: formData.name,
      };

      if (isLmic === "yes") payload.lmic = country;
      if (formData.message !== "") payload.message = formData.message;

      const res = await requestTeamsPackage(payload);
      console.log(res);

      toast({
        position: "bottom-right",
        duration: 5000,
        render: () => <SuccessToast />,
      });
    } catch (error: any) {
      console.log(error);
      let errorMessage = "Failed to send mail. Please try again.";
      if (error && error?.response?.data?.message === "TEAM_NAME_EXISTS") {
        errorMessage = "You already submitted a request for this team name";
      }

      toast({
        description: errorMessage,
        status: "warning",
      });
    } finally {
      // Clear form inputs and reset formData state
      setSending(false);
      e.target.reset();
      setFormData({ name: "", email: "", message: "" });
      setInstitutName("");
      setCountry("");
      setIsLmic("no");
      onClose();
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        p={2}
        alignSelf={"center"}
        minW={{ base: "100%", md: "580px" }}
        minH={"fit-content"}
        maxH={{
          base: "100%",
          md: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        overflowY={"auto"}
        my={"auto"}
        bg={"background"}
        borderRadius={{ base: 0, md: "6px" }}
        as={"form"}
        onSubmit={sendRequest}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={MdOutlineMarkEmailRead}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            Enterprise Plan Request Form
          </Text>
        </ModalHeader>

        <ModalBody mb={4} display={"flex"} flexDirection={"column"} gap={6}>
          <Text color={"gray.600"} fontSize={"14px"} lineHeight={"1.35"}>
            Tell us a bit about your team, and we’ll connect with you to offer a
            tailored enterprise plan for your business.
          </Text>

          <Flex direction={"column"} gap={4}>
            <VisitorName
              name={formData.name}
              isInvalid={errors.name}
              onNameChange={handleNameChange}
            />

            <VisitorEmail
              email={formData.email}
              isInvalid={errors.email}
              onEmailChange={handleEmailChange}
            />

            <Institution name={institutName} setName={setInstitutName} />

            <Lmic
              isLmic={isLmic}
              setIsLmic={setIsLmic}
              country={country}
              setCountry={setCountry}
            />

            <VisitorMessage
              message={formData.message}
              isInvalid={errors.message}
              onMessageChange={handleMessageChange}
            />
          </Flex>
        </ModalBody>

        <ModalFooter gap={1}>
          <Button
            size={"md"}
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            pointerEvents={sending ? "none" : "auto"}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            size={"md"}
            bg={"highlight.primary"}
            color={"gray.50"}
            _hover={{ opacity: 0.9 }}
            isLoading={sending ?? false}
            pointerEvents={sending ? "none" : "auto"}
            borderRadius={"100px"}
            transition={"all .3s ease"}
            loadingText={"sending..."}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default VisitorEntrepriseRequest;
