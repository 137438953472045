import { CiteProps, SourceProps } from "models/chat/MessageProps";
import { SuggestedQuestionProps } from "./SuggestedQuestion";

export const suggestedQuestions: Omit<
  SuggestedQuestionProps,
  "onSendSuggestedQuestion"
>[] = [
  {
    question:
      "What alternatives exist for peginterferon/ribavirin treatment for hepatitis C?",
    header: "Hepatitis C Treatment Alternatives",
  },
  {
    question:
      "Which SGLT2 inhibitors have outperformed other diabetes treatments without compromising patient quality of life?",
    header: "Optimal SGLT2 Inhibitors in Diabetes Care",
  },
  {
    question:
      "What antihypertensive drugs have shown efficacy in reducing systolic blood pressure by more than 20 mmHg?",
    header: "Effective Antihypertensives for Major BP Reduction",
  },
  {
    question:
      "Which molecules have shown promise in targeting the Wnt signaling pathway in colorectal cancer therapies?",
    header: "Wnt Pathway Targeting in Colorectal Cancer Therapies",
  },
];

export function removeAsterisks(str: string) {
  return str.replace(/\*\*/g, "");
}

export function emboldenText(text: string) {
  const boldPattern = /\*\*(.*?)\*\*/g;
  return text.replace(boldPattern, '<strong class="bold">$1</strong>');
}

export function mergeStrings(str1: string, str2: string): string | null {
  // Extracting id1 and id2 from str1 and str2 respectively
  const regex: RegExp = /\b[a-zA-Z0-9]{24}\b/g;

  const ids1: string[] = str1.match(regex) || [];
  const ids2: string[] = str2.match(regex) || [];

  // if str1 has no citation, return str2, because str2 includes str1 and citation
  if (!ids1 || ids1?.length === 0) {
    return str2;
  }

  // if str2 has no citation, return str1, because str1 includes str2 and citation
  if (!ids2 || ids2?.length === 0) {
    return str1;
  }

  const joinedIds1 = ids1?.join(",");
  const joinedIds2 = ids2?.join(",");

  // Extracting positions of id1 and id2 in str1 and str2
  const pos1: number = str1.indexOf(`${joinedIds1}`);
  const pos2: number = str2.indexOf(`${joinedIds2}`);

  if (pos1 === -1 || pos2 === -1) {
    return null;
  }

  // Removing id1 and id2 with their wrapping parentheses
  const editedStr1: string = str1.replace(`${joinedIds1}`, "");
  const editedStr2: string = str2.replace(`${joinedIds2}`, "");

  // Checking if the edited strings are identical
  if (editedStr1 !== editedStr2) {
    return null;
  }

  // Constructing the final output string
  const output: string = `${editedStr1.slice(0, pos1)}${joinedIds1}${str2.slice(
    pos1
  )}`;

  return output;
}

export function getAllCitesIDs(citations: CiteProps[]) {
  let cites: string[] = [];

  if (!citations || citations?.length === 0) {
    return [];
  }

  citations.forEach((citation) => cites.push(...citation?.cite));
  const uniqueCites = [...new Set(cites)]?.flat();

  return uniqueCites;
}

export function replaceCitesWithSources(
  citations: CiteProps[],
  sources: SourceProps[],
  text: string
) {
  // list of all unique cites in this bot reply
  const cites = getAllCitesIDs(citations);

  cites.forEach((c) => {
    const sourceIndex = sources.findIndex((source) => source.id === c);

    if (sourceIndex === -1) return;
    else {
      // placeholder replacement
      const placeholder = `<citation-node index="${sourceIndex}"></citation-node>`;

      // replace all 24-length citation ids with placeholder string
      text = text.replaceAll(c, placeholder);
    }
  });

  return text;
}

export function linkSourceToPaperIds(sources: SourceProps[], text: string) {
  // Regular expression to match the citation format
  // const citationRegex = /{{(\[.*?\])}}/g;

  // Function to replace each matched citation
  // const replaceCitation = (match: string, paperIdsString: string) => {
  // const replaceCitation = (match: string, paperIdsString: string) => {
  //   try {
  //     const paperIds = JSON.parse(paperIdsString) as string[];
  //     const citationNodes = paperIds.map(id => {
  //       const sourceIndex = sources.findIndex(source => source.id === id);
  //       if (sourceIndex !== -1) {
  //         return `<citation-node index="${sourceIndex}"></citation-node>`;
  //       }
  //       return '';
  //     }).filter(node => node !== '');

  //     return citationNodes.join(' ') || match;
  //   } catch (error) {
  //     console.error('Error parsing citation:', error);
  //     return match;
  //   }
  // };

  const citationRegex = /{{(\[.*?\])}}|{(\[.*?\])}/g;
  const replaceCitation = (
    match: string,
    paperIdsString1: string,
    paperIdsString2: string
  ) => {
    // Use whichever is not undefined (since one of the groups will be undefined depending on the match)
    const paperIdsString = paperIdsString1 || paperIdsString2;

    try {
      const paperIds = JSON.parse(paperIdsString) as string[];
      const citationNodes = paperIds
        .map((id) => {
          const sourceIndex = sources.findIndex((source) => source.id === id);
          if (sourceIndex !== -1) {
            return `<citation-node index="${sourceIndex}"></citation-node>`;
          }
          return "";
        })
        .filter((node) => node !== "");

      // return citationNodes.join(' ') || match;
      return citationNodes.join(" ") || "";
    } catch (error) {
      console.error("Error parsing citation:", error);
      // return match;
      return "";
    }
  };

  // Replace all citations in the text
  return text.replace(citationRegex, replaceCitation);
}

export function fixAndValidateArrayString(str: string) {
  // Step 1: Ensure the string starts with '[' and ends with ']'
  if (!str.startsWith("[")) {
    str = "[" + str;
  }
  if (!str.endsWith("]")) {
    str = str + "]";
  }

  // Step 2: Extract content between the brackets
  let content = str.slice(1, -1).trim();

  // Step 3: Split items by comma and validate each one
  let items = content.split(",").map((item: string) => item.trim());
  let fixedItems = items.map((item: string) => {
    // Remove extra quotes and ensure each item is surrounded by double quotes
    let cleanedItem = item.replace(/^"|"$/g, "");
    return `"${cleanedItem}"`; // Ensure it has quotes
  });

  // Step 4: Join the items back into a string and wrap with brackets
  return `[${fixedItems.join(",")}]`;
}
