import { useContext } from "react";
import { Tag, useTheme } from "@chakra-ui/react";

import { SourceProps } from "models/chat/MessageProps";
import { BotMessageContext } from "./BotMessage";
import { hexToRgba } from "utils/helpers";

interface CitationNodeProps {
  index: number;
  citeID?: string;
  sources: SourceProps[];
}

function CitationNode({ index, citeID, sources }: CitationNodeProps) {
  // Context
  const { setOpenAccordionIndex, scrollToAccordion } =
    useContext(BotMessageContext);

  // Theme
  const { colors } = useTheme();

  // Handler
  function handleCiteClick(cite: string) {
    if (!cite) return;

    const index = sources.findIndex((source) => source.id === cite);
    setOpenAccordionIndex(index);
    setTimeout(() => scrollToAccordion(), 100);
  }

  return (
    <Tag
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      fontSize={"10px"}
      lineHeight={"1"}
      minH={"fit-content"}
      minW={"fit-content"}
      fontWeight={"400"}
      size={"sm"}
      px={1}
      py={0.5}
      mx={"2px"}
      borderRadius={"4px"}
      borderWidth={1}
      borderColor={hexToRgba(colors.highlight.primary, 0.16)}
      color={"highlight.primary"}
      bg={hexToRgba(colors.highlight.primary, 0.12)}
      _hover={{
        transition: "all 0.3s ease",
        borderColor: hexToRgba(colors.highlight.primary, 0.5),
      }}
      transition={"all 0.3s ease"}
      onClick={() => handleCiteClick(citeID ?? "")}
    >
      {index + 1}
    </Tag>
  );
}

export default CitationNode;
