import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useQueries } from "@tanstack/react-query";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { AxiosError } from "axios";

import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import Sources from "./Sources";
import Molecules from "./Molecules";
import { BotMessageContext } from "./BotMessage";
import useGuideTour from "hooks/guideTour/useGuideTour";

import { useCompoundsAPI } from "api/useCompoundsAPI";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";

import { PaperNetworkViewProps } from "views/papers/NetworkTools";
import { CompoundProps } from "models/compounds/CompoundProps";
import { SourceProps } from "models/chat/MessageProps";
import { retry } from "@reduxjs/toolkit/dist/query";

interface ReferencesProps {
  sources: SourceProps[];
  molecules: string[] | undefined;
}

function References({ sources, molecules }: ReferencesProps) {
  // Context
  const { openAccordionIndex, resetOpenAccordionIndex } =
    useContext(BotMessageContext);

  // Hooks
  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const { isHighlighted } = useGuideTour();
  const { fetchCompoundsByName } = useCompoundsAPI();
  const { fetchSimilarPapers } = useLiteraturesAPI();

  let sourcesTabHighlighted = isHighlighted("chat_sources");
  let moleculesTabHighlighted = isHighlighted("chat_molecules");
  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  // API
  // * Fetch sources
  const sourcesQueries = useQueries({
    queries: sources.map((source: SourceProps) => ({
      queryKey: ["literatures", "network", source.id, source.db_type],
      queryFn: fetchSimilarPapers,
      staleTime: 40 * 1000 * 60, // After 40 mins, a refetch will be triggered the next time the query is accessed
      gcTime: 30 * 1000 * 60, // After 30 mins, remove unused cached data
      // retry: 1, // the query function will be retried once more in case of failure
    })),
  });

  //   * Fetch referenced compounds
  const moleculesQueries = useQueries({
    queries: (molecules || []).map((cmpd: string) => {
      return {
        queryKey: ["compounds-by-name", cmpd],
        queryFn: fetchCompoundsByName,
        staleTime: 40 * 1000 * 60,
        gcTime: 30 * 1000 * 60,
        // NOTE: retry and enabled don't fix the actual issue.
        // reminder: the issue was whenever user selects Molecules Tab, a re-fetch occurs for failed queries.
        // The fix takes place in parent component References: muting isLazy property in Tabs component
        // this prop. was causing the re-fetch for failed queries
        // -------
        // retry: (failureCount: any, error: any) => {
        //   const failedUrl = error?.config?.url;
        //   const newFailedQuery: string =
        //     failedUrl?.split("get_compound_by_name/")[1]?.toLowerCase() ?? "";

        //   setFailedQueries((p) => [...p, newFailedQuery]);
        //   return false;
        // },
        // enabled: !failedQueries?.includes(cmpd?.toLowerCase()), // Prevent refetching for failed compounds
      };
    }),
  });

  const allSourcesFinished = sourcesQueries.every((query) => !query.isLoading);
  // const noSourceErrorFound = sourcesQueries.every((query) => !query.error);
  const allSourcesErrorsFound = sourcesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allMolsFinished = moleculesQueries.every((query) => !query.isLoading);
  const allMolsErrorsFound = moleculesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });
  const sourcesData = useMemo(() => {
    return sourcesQueries
      .filter((query) => query.data !== undefined)
      .map((query) => query.data as PaperNetworkViewProps);
  }, [sourcesQueries]);

  const moleculesData = useMemo(() => {
    return moleculesQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as CompoundProps);
  }, [moleculesQueries]);

  const hasSources = sources && sources?.length > 0 && sourcesData?.length > 0;
  const hasMolecules =
    molecules && molecules?.length > 0 && moleculesData?.length > 0;

  // If bot reply has no sources and no molecules
  if (!hasSources && !hasMolecules) {
    return <></>;
  }

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      index={openAccordionIndex !== null ? 0 : undefined}
    >
      <TabList
        bg={"transparent"}
        color={"gray.500"}
        borderBottomWidth={1}
        borderColor={applyIsGuideOpenStyles ? "transparent" : "gray.200"}
      >
        {hasSources && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={sourcesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_sources" px={3}>
              SOURCES
            </Box>
          </Tab>
        )}
        {hasMolecules && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={moleculesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_molecules" px={3}>
              MOLECULES
            </Box>
          </Tab>
        )}
      </TabList>
      <TabIndicator
        mt={"-2px"}
        h="2px"
        bg={applyIsGuideOpenStyles ? "inherit" : "highlight.primary"}
        borderRadius="1px"
      />
      <TabPanels>
        {hasSources && (
          <TabPanel p={1}>
            <Sources
              sources={sourcesData}
              allFetched={allSourcesFinished}
              allFetchFailed={allSourcesErrorsFound}
            />
          </TabPanel>
        )}
        {hasMolecules && (
          <TabPanel p={1}>
            <Molecules
              molecules={moleculesData}
              allFetched={allMolsFinished}
              allFetchFailed={allMolsErrorsFound}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default References;
