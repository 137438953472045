import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTeamsAPI } from "api/useTeamsAPI";
import Loading from "components/ui/Loading";
import { useState } from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { MdOutlineSmsFailed } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { errorHandler } from "utils/helpers";

export default function AcceptInvite() {
  // Hooks
  const navigate = useNavigate();
  const { acceptTeamInvite } = useTeamsAPI();
  const { user } = useSelector(selectCurrentAuthData);

  const token = new URLSearchParams(useLocation().search).get("token") || "";
  const [invitationResponded, setInvitationResponded] =
    useState<string>("SUCCESS");

  const acceptInviteMutation = useMutation({
    mutationFn: () =>
      acceptTeamInvite({ email: user?.email || "", invite_token: token }),
    onSuccess: () => {
      setInvitationResponded("SUCCESS");
      setTimeout(() => {
        navigate("/team", { replace: true });
        window.location.reload();
      }, 2500);
    },
    onError: (error) => {
      setInvitationResponded(errorHandler(error).message);
      setTimeout(() => {
        navigate("/chat", { replace: true });
        window.location.reload();
      }, 2500);
    },
  });

  if (
    !acceptInviteMutation.isSuccess &&
    !acceptInviteMutation.isError &&
    !acceptInviteMutation.isPending
  ) {
    acceptInviteMutation.mutate();
  }
  if (!token) {
    navigate("/chat", { replace: true });
  }
  const isAccepted = !!(invitationResponded === "SUCCESS");

  return (
    <Flex
      height="100%"
      width={["90%", "80%"]}
      maxW="98%"
      direction="column"
      mx="auto"
      py={4}
      gap="16px"
      overflow={"hidden"}
    >
      {/* Content */}
      <Box pt={2} height={"calc(100% - 58px)"} width="100%">
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Box textAlign="center">
            {invitationResponded ? (
              <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
                <Icon
                  as={isAccepted ? BsPatchCheckFill : MdOutlineSmsFailed}
                  w={8}
                  h={8}
                  color={isAccepted ? "green.500" : "red.500"}
                />
                <Text
                  fontSize="xl"
                  fontWeight="medium"
                  color={isAccepted ? "green.600" : "red.500"}
                >
                  {isAccepted
                    ? "Invitation Accepted Successfully"
                    : invitationResponded}
                </Text>
              </Flex>
            ) : (
              <>
                <Loading pt={5} small={false} />
                <Text
                  pt={5}
                  fontWeight={"600"}
                  color={"gray.600"}
                  fontSize={"14px"}
                >
                  Please wait as we verify your invitation ...
                </Text>
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
