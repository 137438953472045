import { Box, Flex } from "@chakra-ui/react";

import HumanMessage from "./HumanMessage";
import BotMessage from "./BotMessage";

import BotAvatar from "./BotAvatar";
import { ConversationProps } from "models/chat/MessageProps";
import { RefObject } from "react";

interface BubbleProps {
  content: ConversationProps;
  questionOnWait?: string | undefined;
  isLastMessage: boolean;
  messagesRef: RefObject<HTMLDivElement>;
}

export default function MessageBubble({
  content,
  questionOnWait,
  isLastMessage,
  messagesRef,
}: BubbleProps) {
  console.log(questionOnWait, "question send bubble");

  return (
    <Flex direction={"column"}>
      {/* human question */}
      <Flex w="100%" my={4} justifyContent={"flex-end"} textAlign={"end"}>
        <Flex w="80%" pl={"56px"} justifyContent={"flex-end"}>
          <HumanMessage botResponse={content} questionOnWait={questionOnWait} />
        </Flex>
      </Flex>

      {/* bot reply */}
      <Flex w="100%" mb={4} pr={2} pl={2}>
        <Box pt={2}>
          <BotAvatar />
        </Box>
        <BotMessage
          botResponse={content}
          isLastMessage={isLastMessage}
          messagesRef={messagesRef}
        />
      </Flex>
    </Flex>
  );
}
