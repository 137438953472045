import { RefObject, useState, useEffect, useRef } from "react";
import { Flex, Icon, useBreakpointValue } from "@chakra-ui/react";

import { motion } from "framer-motion";
import { useDebounce } from "hooks";

import { FaAnglesDown } from "react-icons/fa6";

interface ScrollDownButtonProps {
  onClick: () => void;
  messagesRef: RefObject<HTMLDivElement>;
}

function ScrollDownButton({ messagesRef, onClick }: ScrollDownButtonProps) {
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const debouncedShow = useDebounce(showScrollDownButton);

  const lastScrollY = useRef(0); // Track previous scrollTop

  function handleScroll() {
    const currentRef = messagesRef?.current;

    if (!!currentRef) {
      const {
        scrollHeight,
        scrollTop: currentScrollY,
        clientHeight,
      } = currentRef;

      // true if user scrolling up
      const isScrollingUp = currentScrollY < lastScrollY.current;

      // reach the bottom of the conversation
      const isAtBottom = scrollHeight - clientHeight <= currentScrollY + 1;

      // Update previous scrollTop value for next check
      if (lastScrollY.current !== currentScrollY) {
        lastScrollY.current = currentScrollY;
      }

      setShowScrollDownButton(!isScrollingUp && !isAtBottom);
    }
  }

  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    } else return;

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef]);

  const leftPosition = useBreakpointValue({
    lg: "calc(50% + 140px)",
    xl: "calc(50% + 150px)",
    "2xl": "calc(50% + 160px)",
  });

  const buttonVariants = {
    open: {
      bottom: "120px",
      opacity: 1,
      pointerEvents: "auto" as const,
    },
    closed: {
      bottom: "60px",
      opacity: 0,
      pointerEvents: "none" as const,
      transition: { duration: 0.3 },
    },
  };

  return (
    <>
      <motion.div
        initial={debouncedShow ? "open" : "closed"}
        animate={debouncedShow ? "open" : "closed"}
        variants={buttonVariants}
      >
        <Flex
          w={8}
          h={8}
          justify={"center"}
          align={"center"}
          cursor={"pointer"}
          boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
          bg={"background"}
          borderWidth={1}
          borderColor={"gray.100"}
          _hover={{
            transition: "all 0.2s ease",
            borderColor: "gray.300",
          }}
          p={5}
          zIndex={1000000}
          position={"fixed"}
          left={leftPosition}
          transform={"translateX(-50%)"}
          bottom={debouncedShow ? "120px" : "60px"}
          opacity={debouncedShow ? 1 : 0}
          borderRadius={{ base: 0, sm: "50%" }}
          backdropFilter={"blur(6px)"}
          transition={"all .5s ease-in-out"}
          onClick={onClick}
        >
          <Icon as={FaAnglesDown} boxSize={3} />
        </Flex>
      </motion.div>
    </>
  );
}

export default ScrollDownButton;
