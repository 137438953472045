import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  Box,
  useToast,
  Flex,
} from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { CustomThickScrollBar } from "components/ui/CustomScrollBar";

import {
  setNotification,
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";

import { TbFolderCheck, TbFolderPlus } from "react-icons/tb";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";
import { SavedFolderProps } from "models/bookmarks/SavedProps";
import ChemicalProps from "models/compounds/ChemicalProps";

import { CheckboxStyled } from "components/checkbox/Checkbox";
import FoldersListItem from "../FoldersListItem";
import NewFolderContent from "../NewFolderContent";
import { errorHandler } from "utils/helpers";

interface FoldersModalProps {
  isOpen: boolean;
  onClose: () => void;
  payload: {
    saveElementPayload: {
      elementType: "COMPOUND" | "LITERATURE" | "ASSAY" | "BOTMESSAGE";
      content: {
        elementId: string;
        aliases?: string[];
        chemical_props?: ChemicalProps;
        generic_name?: string;
        selected_feature?: string;
        is_private?: boolean;
      };
    };
    successMessage: string;
  };
}

// Styles
const listStyle = {
  height: "165px",
  width: "100%",
  padding: "4px 8px 4px 0",
  margin: "0 0 12px 0",
};

export default function FoldersModal({
  isOpen,
  onClose,
  payload,
}: FoldersModalProps) {
  const toast = useToast();

  // Redux
  const { loading, savedFolders } = useSelector(selectCurrentSavedData);

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // States
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Helper
  function getStoredItem(item: string) {
    let hasNotificationShown = false;

    const storedHasNotificationShown = localStorage.getItem(item);

    if (storedHasNotificationShown) {
      try {
        hasNotificationShown = JSON.parse(storedHasNotificationShown);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    }

    return hasNotificationShown;
  }

  // Handlers
  async function handlerSaveElement() {
    if (!selectedFolder) return;

    try {
      setSaving(true);
      const response = await axiosPrivate.put(
        `${environment.BACKEND_API}/api/saved_elements`,
        {
          ...payload.saveElementPayload,
          folderId: selectedFolder,
        }
      );

      const data = response.data as SavedFolderProps;
      const newFolders = savedFolders?.map((f: SavedFolderProps) => {
        return f.id === data.id ? data : f;
      });

      dispatch(setSuccess(newFolders));
      if (!hasNotificationShown) dispatch(setNotification(true));

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            {payload.successMessage}
          </Box>
        ),
      });

      setSelectedFolder("");
      onClose();
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
    } finally {
      setSaving(false);
    }
  }

  async function handleDelete() {
    if (!selectedFolder) return;

    setDeleting(false);

    try {
      setDeleting(true);

      await axiosPrivate.delete(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${selectedFolder}`
      );

      // update store
      let newFolders: SavedFolderProps[] = savedFolders?.filter(
        (folder: SavedFolderProps) => folder.id !== selectedFolder
      );
      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder deleted successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
    } finally {
      setDeleting(false);
      setSelectedFolder("");
    }
  }

  const hasNotificationShown = getStoredItem("hasNotificationShown");

  const hasFoldersToShow = useMemo(() => {
    let foldersPresent = false;
    if (window.location.pathname.includes("team")) {
      foldersPresent =
        savedFolders?.filter((x) => x?.isShared === true).length > 0;
    } else {
      foldersPresent = savedFolders?.length > 0;
    }
    return foldersPresent;
  }, [savedFolders]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"500px"}
        minH={"300px"}
        borderRadius={"6px"}
        bg={"background"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
          py={3}
          px={4}
        >
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Icon
              as={hasFoldersToShow ? TbFolderCheck : TbFolderPlus}
              bg={"highlight.primary"}
              color={"gray.50"}
              boxSize={"28px"}
              borderRadius={"6px"}
              p={"3px"}
            />
            <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
              {hasFoldersToShow
                ? `Save to bookmark folder:`
                : `Create new bookmark folder:`}
            </Text>
          </Box>
        </ModalHeader>

        <ModalBody h={"100%"} display={"flex"} py={3} px={4}>
          {!!loading ? (
            <Flex h={"165px"} w={"100%"}>
              <Loading message="Loading folders.." />
            </Flex>
          ) : hasFoldersToShow ? (
            <Flex direction={"column"} w={"100%"} gap={3}>
              <Flex direction={"column"} w={"100%"} gap={1}>
                <Text
                  fontSize={"14px"}
                  fontFamily={"Poppins, sans-serif"}
                  lineHeight={"1.3"}
                  color={"gray.600"}
                >
                  Select one of the following folders:
                </Text>
                <CustomThickScrollBar style={listStyle}>
                  <Flex direction={"column"} gap={2}>
                    {savedFolders?.map((folder: SavedFolderProps, i) => (
                      <FoldersListItem
                        key={i}
                        folder={folder}
                        selectedFolder={selectedFolder}
                        deleting={deleting}
                        onClick={() => setSelectedFolder(folder?.id ?? "")}
                        onDelete={handleDelete}
                      />
                    ))}
                  </Flex>
                </CustomThickScrollBar>
              </Flex>

              <Flex direction={"column"} gap={1} pl={0.5} mt={"auto"}>
                <CheckboxStyled
                  roundedFull
                  forTheme
                  isChecked={isChecked}
                  onChange={() => setIsChecked((c) => !c)}
                >
                  <Flex align={"center"} maxW={"full"}>
                    <Text
                      wordBreak="break-word"
                      fontFamily={"Poppins, sans-serif"}
                      fontSize={"12px"}
                    >
                      Want to create new folder?
                    </Text>
                  </Flex>
                </CheckboxStyled>
                {isChecked && (
                  <NewFolderContent hasFolders={hasFoldersToShow} />
                )}
              </Flex>
            </Flex>
          ) : (
            <NewFolderContent hasFolders={hasFoldersToShow} />
          )}
        </ModalBody>

        {hasFoldersToShow && (
          <ModalFooter gap={2} px={3} pb={4} pt={0}>
            <Button
              bg={"gray.100"}
              color={"gray.500"}
              _hover={{ bg: "gray.200" }}
              borderRadius={"100px"}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              bg={"highlight.primary"}
              color={"gray.50"}
              border={"none"}
              _hover={{ opacity: 0.9 }}
              isDisabled={!selectedFolder}
              borderRadius={"100px"}
              loadingText={`Saving...`}
              isLoading={saving}
              onClick={handlerSaveElement}
            >
              Select
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
