import { Text, View } from "@react-pdf/renderer";
import { SectionHeader } from "./BookmarkToExport";
import { clip } from "assets/bookmarks/reports/icons";

import { SavedLiteratureProps } from "models/bookmarks/SavedProps";
import { getPaperColor } from "views/compounds/helpers";
import {
  documentStyles,
  literatureCols,
  literatureStyles,
  tableStyles,
} from "./helpers";

interface Props {
  literature: SavedLiteratureProps[];
}

function LiteratureToExport({ literature }: Props) {
  return (
    <>
      {literature && literature?.length > 0 && (
        <View style={documentStyles.section}>
          {/* header */}
          <SectionHeader src={clip} name={"Literature"} />

          {/* table */}
          <View style={tableStyles.container}>
            {/* Table Header */}
            <View style={tableStyles.header}>
              {literatureCols.map((col, index: number) => (
                <Text
                  key={`literature-table-header-${index}`}
                  style={[tableStyles.headerCell, { width: col.width }]}
                >
                  {col.name}
                </Text>
              ))}
            </View>

            {/* Table Body */}
            <View style={tableStyles.body}>
              {literature.map((lit, index: number) => {
                return (
                  <View
                    key={`assay-row-${index}`}
                    style={tableStyles.row}
                    wrap={false}
                  >
                    {/* name */}
                    <Text
                      style={[
                        tableStyles.cell,
                        {
                          width: "50%",
                          lineHeight: 1.3,
                          fontSize: "11px",
                        },
                      ]}
                    >
                      {lit?.title}
                    </Text>

                    {/* lens ID */}
                    <Text
                      style={[
                        tableStyles.cell,
                        { lineHeight: 1.3, fontSize: "10px", width: "20%" },
                      ]}
                    >
                      {lit?.lens_id}
                    </Text>

                    {/* year */}
                    <Text
                      style={[
                        tableStyles.cell,
                        { lineHeight: 1.3, fontSize: "10px", width: "10%" },
                      ]}
                    >
                      {/* NOTE: tp prevent 0-valued year_published */}
                      {!!parseInt(lit?.year_published)
                        ? lit?.year_published
                        : "N/A"}
                    </Text>

                    {/* type */}
                    <View
                      style={[
                        tableStyles.cell,
                        { width: "20%", textAlign: "center" },
                      ]}
                    >
                      <Text
                        style={[
                          literatureStyles.type,
                          {
                            backgroundColor: getPaperColor(
                              lit?.paper_type?.toLowerCase()
                            ),
                          },
                        ]}
                      >
                        {lit?.paper_type ?? "Unclassified"}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

export default LiteratureToExport;
