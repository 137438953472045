import { Box, Flex, HStack, Text, useTheme } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useTeamsAPI } from "api/useTeamsAPI";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import useFetchSavedElements from "hooks/bookmarks/useFetchSavedElements";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  selectCurrentPostsData,
  setTeamData,
} from "redux/features/posts/postsSlice";
import { errorHandler, hexToRgba } from "utils/helpers";

export default function Team() {
  const titles = ["Members", "Folders", "Posts", "Activity"];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getTeam } = useTeamsAPI();
  const { colors } = useTheme();

  let { unReadMessages } = useSelector(selectCurrentPostsData);
  const { user } = useSelector(selectCurrentAuthData);

  useFetchSavedElements();

  const { data, isLoading, isRefetching, error } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: 1,
  });

  useEffect(() => {
    if (data) dispatch(setTeamData(data));
  }, [data, dispatch]);

  return (
    <Flex
      height="100%"
      width={["90%", "80%"]}
      maxW="98%"
      direction="column"
      mx="auto"
      py={4}
      gap="16px"
      overflow={"hidden"}
    >
      {/* Tabs */}
      <Flex
        w={"100%"}
        justify={"center"}
        align={"center"}
        p={3}
        borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
        borderBottomWidth={1}
      >
        <Flex w={"fit-content"} h={"fit-content"}>
          {location.pathname.includes("accept-invite") === false && (
            <>
              {titles.map((title, index) => {
                if (user?.id !== data?.owner && title === "Activity") return;

                const isSelected = location.pathname.includes(
                  title.toLowerCase()
                );
                const isRoot = location.pathname === "/team" && index === 0;
                const isActive = isSelected || isRoot;
                return (
                  <Box
                    key={index}
                    px={5}
                    py={2}
                    cursor="pointer"
                    w={"fit-content"}
                    textAlign={"center"}
                    borderRadius={"99px"}
                    bg={
                      isActive
                        ? hexToRgba(colors.blue[300], 0.15)
                        : "transparent"
                    }
                    color={isActive ? "blue.500" : "gray.500"}
                    transition={"all .3s ease"}
                    onClick={() =>
                      navigate(
                        `/team${
                          title === "Members" ? "" : "/" + title.toLowerCase()
                        }`
                      )
                    }
                  >
                    <HStack justifyContent={"space-between"}>
                      <Text fontSize="md">{title}</Text>
                      {title === "Posts" && unReadMessages > 0 && (
                        <Box
                          bg={"red.500"}
                          borderRadius={"full"}
                          width={2.5}
                          height={2.5}
                        ></Box>
                      )}
                    </HStack>
                  </Box>
                );
              })}
            </>
          )}
        </Flex>
      </Flex>

      {/* Content */}
      <Box pt={2} height={"calc(100% - 58px)"} width="100%">
        {isLoading || isRefetching ? (
          <Flex
            h={"100%"}
            w={"100%"}
            align={"center"}
            justify={"center"}
            color={"gray.500"}
            direction={"column"}
          >
            <Loading message="Loading team data ..." />
          </Flex>
        ) : error ? (
          <MainPanelError errorMessage={errorHandler(error).message} />
        ) : (
          <Outlet />
        )}
      </Box>
    </Flex>
  );
}
