import { fixAndValidateArrayString } from "components/chat/helpers";
import { CitesProps } from "models/chat/MessageProps";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

interface UseWSProps {
  waitingOnBot: boolean | undefined;
}

// used to persist streaming content on navigation
let reply: string[] = [];

const useWS = ({ waitingOnBot }: UseWSProps) => {
  const [notifications, setNotifications] = useState<string[]>([]);
  const [partialReply, setPartialReply] = useState<string[]>([]);
  const [cites, setCites] = useState<CitesProps[]>([]);

  // Auth
  const { user } = useSelector(selectCurrentAuthData);
  const HOST_URL = window.location.hostname;

  const WS_URL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `ws://127.0.0.1:8000/ws/chat/${user?.id}/`
      : `wss://${HOST_URL}/ws/chat/${user?.id}/`;

  let socket: WebSocket | null = null;

  // Buffering citations
  let tempBuffer = ""; // buffers what comes inside {{ }}
  let isInsideBraces = false;
  let citeArr: CitesProps[] = [];
  let prevIndex = cites.length - 1;

  function processReplyChunk(msg: string) {
    let processedMessage = "";
    let newSources: CitesProps[] = [];

    for (let i = 0; i < msg.length; i++) {
      if (msg[i] === "{" && msg[i + 1] === "{") {
        isInsideBraces = true;
        i++; // skip the next '{'
      } else if (msg[i] === "}" && msg[i + 1] === "}") {
        isInsideBraces = false;
        i++; // skip the next '}'
        //* Save the complete variable string
        try {
          tempBuffer = fixAndValidateArrayString(tempBuffer);
          const parsedPaperIdsArray: string[] = JSON.parse(tempBuffer);
          newSources = parsedPaperIdsArray
            // eslint-disable-next-line no-loop-func
            .filter((id) => {
              const existingIndex = citeArr.findIndex((item) => item.id === id);
              if (existingIndex !== -1) {
                processedMessage += `<citation-node index="${existingIndex}"></citation-node>`;
                return false;
              } else {
                prevIndex += 1;
                processedMessage += `<citation-node index="${prevIndex}"></citation-node>`;
                return true;
              }
            })
            .map((id) => ({ id }));
          if (newSources.length > 0) {
            // eslint-disable-next-line no-loop-func
            setCites((prevSources) => [...prevSources, ...newSources]);
            citeArr = [...citeArr, ...newSources];
          }
        } catch (error) {
          console.error("Invalid variable format:", error);
        }
        tempBuffer = ""; // Clear temp buffer
      } else if (!isInsideBraces) {
        processedMessage += msg[i]; // Append to result if outside braces
      } else {
        tempBuffer += msg[i]; // Capture variable inside double braces
      }
    }

    if (processedMessage) {
      setPartialReply((list) => {
        reply.push(processedMessage);
        return [...list, processedMessage];
      });
    }
  }

  // websocket connection handler
  function handleStreaming() {
    socket = new WebSocket(WS_URL);
    socket.onopen = () => {
      setPartialReply([...reply]); // keep previous streaming content when switching between existing chat and new chat
      setNotifications([]);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { message, action } = data;

      if (action === "notification") {
        setNotifications((list) => [...list, message]);
      } else if (action === "reply") {
        processReplyChunk(message);
      }
    };

    socket.onclose = () => {};
  }

  // enable user engagement feature while waiting on bot
  useEffect(() => {
    if (waitingOnBot) {
      handleStreaming();
    } else {
      reply = []; // no need to store previous streaming content
    }

    // cleanup: close socket connection when all requested data received
    return () => {
      !!socket && socket.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingOnBot]);

  return { notifications, partialReply, cites };
};

export default useWS;
