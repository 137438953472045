import { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SortingState } from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";

import { Box, Flex } from "@chakra-ui/react";
import CoreBreadcrumb from "./Breadcrumb";
import { getCoreSource, getMRNASource } from "../helpers";
import { useLastViewPath } from "hooks/library/persistPath";
import { useLayersAPI } from "api/useLayersAPI";
import MainPanelError from "components/ui/MainPanelError";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";
import { LayersProps } from "./CoreLayersList";

export interface ColumnFiltersProp {
  [key: string]: string;
}

export interface FiltersState {
  showFilter: string | null;
  filters: ColumnFiltersProp;
}

interface SearchStateProps {
  searchValue: string;
  filters: ColumnFiltersProp;
  columnsFilters: FiltersState;
  sorting: SortingState;
}
interface CoreContextType {
  layers: LayersProps[];
  sourceItemName: string;
  setSourceItemName: React.Dispatch<React.SetStateAction<string>>;
  results: any[] | null;
  setResults: React.Dispatch<React.SetStateAction<any[] | null>>;
  searchState: SearchStateProps;
  setSearchState: React.Dispatch<React.SetStateAction<SearchStateProps>>;
}

const initialContextState: CoreContextType = {
  layers: [],
  sourceItemName: "",
  setSourceItemName: () => {},
  results: null,
  setResults: () => {},
  searchState: {
    searchValue: "",
    columnsFilters: { showFilter: null, filters: {} },
    filters: {},
    sorting: [],
  },
  setSearchState: () => {},
};

export const CoreContext = createContext<CoreContextType>(initialContextState);

const CorePanel = () => {
  // States
  const [sourceItemName, setSourceItemName] = useState("");
  const [results, setResults] = useState<any[] | null>(null);
  const [searchState, setSearchState] = useState<SearchStateProps>({
    searchValue: "",
    filters: {},
    columnsFilters: { showFilter: null, filters: {} },
    sorting: [],
  });

  // Hooks
  const navigate = useNavigate();
  const { layerName, source, sourceItemId, toolName } = useParams();
  const { getSpecificLibraryLastPath } = useLastViewPath();
  const coreSource = getCoreSource(source);
  const mRNASource = getMRNASource(source);

  const { getLayers } = useLayersAPI();

  const {
    isLoading: isLoadingLayers,
    error: layersError,
    data: layers,
  } = useQuery({
    queryKey: ["layers"],
    queryFn: getLayers,
    staleTime: 40 * 1000 * 60,
    gcTime: 30 * 1000 * 60,
    retry: false,
    enabled: true,
  });

  useEffect(() => {
    !sourceItemId && setSourceItemName("");
  }, [navigate, sourceItemId]);

  useEffect(() => {
    setSearchState({
      searchValue: "",
      filters: {},
      columnsFilters: { showFilter: null, filters: {} },
      sorting: [],
    });
    setResults(null);
  }, [source]);

  useEffect(() => {
    const lastPath = getSpecificLibraryLastPath("core");
    if (lastPath) {
      navigate(lastPath);
    }
  }, []);

  // if error
  if (!!layersError)
    return (
      <Flex w={"100%"} h={"100%"}>
        <MainPanelError errorMessage={errorHandler(layersError).message} />
      </Flex>
    );

  // if Loading
  if (!!isLoadingLayers)
    return (
      <Flex
        direction={"column"}
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading layers.." />
      </Flex>
    );

  return (
    <Flex h={"100%"} direction={"column"} gap={4}>
      {/* Breadcrumb */}
      <CoreBreadcrumb
        layer={layerName ?? ""}
        source={coreSource ?? mRNASource}
        name={sourceItemName}
        toolName={toolName ?? ""}
      />

      <Box w={"100%"} h={"100%"}>
        <CoreContext.Provider
          value={{
            layers,
            sourceItemName,
            setSourceItemName,
            searchState,
            setSearchState,
            results,
            setResults,
          }}
        >
          <Outlet />
        </CoreContext.Provider>
      </Box>
    </Flex>
  );
};

export default CorePanel;
