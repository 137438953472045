import {
  Flex,
  Text,
  Avatar,
  Heading,
  useTheme,
  Highlight,
  SimpleGrid,
  useBreakpointValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import { pipelineStages } from "views/home/helpers";
import CircleStep from "components/home/pipeline/CircleStep";

import { hexToRgba } from "utils/helpers";

const bgPosition = keyframes`
  from { background-position: 0% center; }
  to { background-position: -200% center; }`;

export default function Pipeline() {
  // Theme
  const { colors } = useTheme();

  // Responsiveness
  const isMobile = useBreakpointValue({ base: true, lg: false });

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const bgPositionAnimation = prefersReducedMotion
    ? undefined
    : `${bgPosition} 4s linear infinite`;

  return (
    <Flex
      direction={"column"}
      maxW={"1440px"}
      mx={"auto"}
      w={"95%"}
      my={"80px"}
      gap={10}
    >
      {/* Heading */}
      <Flex direction={"column"} textAlign={"center"} gap={4}>
        <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
          {"Research boost".toUpperCase()}
        </Text>
        <Heading size={"lg"} fontFamily={"monospace"}>
          {"From Start to Finish"}
        </Heading>
      </Flex>

      {/* Pipeline */}
      <Flex direction={"column"} gap={10}>
        {pipelineStages?.map((item, index) => {
          const isOddIndex = index % 2 !== 0;

          return (
            <SimpleGrid
              columns={{ base: 2, lg: 3 }}
              templateColumns={{ base: "auto 1fr", lg: "1fr auto 1fr" }}
              gap={{ base: 6, lg: "40px", xl: "60px" }}
              w={{ base: "100%", md: "min(680px, 100%)", lg: "100%" }}
              mx={"auto"}
              key={index}
            >
              {/* step details */}
              <Flex
                w={{ base: "fit-content", lg: "100%" }}
                order={{ base: 2, lg: isOddIndex ? 3 : 1 }}
                justify={
                  isMobile
                    ? "flex-start"
                    : isOddIndex
                    ? "flex-start"
                    : "flex-end"
                }
              >
                {/* Heading (+ coming soon for last step) and description  */}
                <Flex
                  p={2}
                  gap={4}
                  direction={"column"}
                  align={
                    isMobile
                      ? "flex-start"
                      : isOddIndex
                      ? "flex-start"
                      : "flex-end"
                  }
                  textAlign={isMobile ? "left" : isOddIndex ? "left" : "right"}
                  w={{ base: "fit-content", lg: "min(470px, 100%)" }}
                >
                  {/* Heading and coming soon */}
                  <Flex direction={"column"}>
                    {/* Dissemination step: coming soon */}
                    {item?.name?.toLowerCase() === "dissemination" && (
                      <Text
                        fontSize={"12px"}
                        textTransform={"uppercase"}
                        fontFamily={"Poppins, sans-serif"}
                        fontWeight={"700"}
                        background={`linear-gradient(to right, #00C1B4, ${colors.purple[500]},  #1A86CE, #00C1B4)`}
                        animation={bgPositionAnimation}
                        backgroundSize={"200%"}
                        whiteSpace={"nowrap"}
                        bgClip={"text"}
                        w={"fit-content"}
                        ml={{ base: 0, lg: "auto" }}
                      >
                        Coming soon
                      </Text>
                    )}

                    <Heading size="md">{item.name}</Heading>
                  </Flex>

                  {/* description */}
                  <Text as={"span"} fontSize={{ base: "sm", lg: "md" }}>
                    <Highlight
                      query={item.description.highlight}
                      styles={{
                        px: 2,
                        py: 0.5,
                        rounded: "full",
                        bg: hexToRgba(item.color, 0.3),
                        lineHeight: "1.8",
                        opacity: 0.8,
                      }}
                    >
                      {item.description.text}
                    </Highlight>
                  </Text>
                </Flex>
              </Flex>

              {/* circle step */}
              <Flex
                w={"100%"}
                order={{ base: 1, lg: 2 }}
                align={"center"}
                justify={"center"}
              >
                <CircleStep
                  stepNumber={(index + 1).toString()}
                  direction={isMobile || !isOddIndex ? "right" : "left"}
                  color={item.color}
                />
              </Flex>

              {/* example details */}
              {!isMobile && (
                <Flex
                  w={"100%"}
                  order={isOddIndex ? 1 : 3}
                  justify={isOddIndex ? "flex-end" : "flex-start"}
                >
                  <Flex
                    p={5}
                    gap={4}
                    align={"center"}
                    w={"min(340px, 100%)"}
                    h={"fit-content"}
                    my={"auto"}
                    boxShadow={"lg"}
                    bg={"background"}
                    fontSize={{ base: "xs", lg: "sm" }}
                  >
                    <Avatar name="" src={item.example.avatar} />
                    <Text>{item.example.query}</Text>
                  </Flex>
                </Flex>
              )}
            </SimpleGrid>
          );
        })}
      </Flex>
    </Flex>
  );
}
