import { Flex } from "@chakra-ui/react";

import FAQ from "./faq";
import Promo from "./promo";
import AboutUs from "./about";
import Footer from "./footer";
import Header from "./header";
import Contact from "./contact";
import Pricing from "./pricing";
import UseCases from "./usecases";
import ActionCall from "./action";
import Features from "./features";
import Pipeline from "./pipeline";
import TargetAudience from "./audience";

export default function Home() {
  return (
    <Flex direction={"column"} bg={"white"}>
      <Header />
      <Promo />
      <UseCases />
      <Pipeline />
      <Features />
      <Flex
        direction={"column"}
        bg={"neutral.200"}
        my={"80px"}
        py={"80px"}
        gap={"80px"}
        id={"about"}
      >
        <AboutUs />
        <TargetAudience />
      </Flex>
      <FAQ />
      <Contact />
      <ActionCall />
      <Pricing inHome />
      <Footer />
    </Flex>
  );
}
