import { Flex, Icon, Image, Text, useToast } from "@chakra-ui/react";
import { BsFileEarmarkPdf, BsFileEarmark, BsX } from "react-icons/bs";

interface FilePreviewProps {
  files: File[];
  onRemoveFile: (index: number) => void;
  source: string;
}

export default function TextFieldFiles({
  files,
  onRemoveFile,
  source,
}: FilePreviewProps) {
  const toast = useToast();

  if (!files) return null;

  const MAX_SIZE_MB = 20;
  const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

  // Filter valid files
  const validFiles = files.filter((file) => {
    if (file.size > MAX_SIZE_BYTES) {
      toast({
        description: `File ${file.name} exceeds the maximum allowed size of ${MAX_SIZE_MB} MB. Please upload a smaller file.`,
        status: "error",
        position: "top-right",
      });
      return false;
    }
    return true;
  });

  return (
    <>
      {validFiles.map((file, index) => (
        <Flex
          key={index}
          position="relative"
          alignItems="center"
          bg="background"
          borderRadius="md"
          mt={3}
          ml={source === "input" ? "55px" : 0}
          w="90%"
        >
          {file.type.startsWith("image/") ? (
            <Flex
              position="relative"
              _hover={{
                "> .close-icon": { display: "block" },
              }}
            >
              <Image
                src={URL.createObjectURL(file)}
                alt="Preview"
                boxSize={source === "input" ? "50px" : "150px"}
                borderRadius="md"
                objectFit="scale-down"
                mr={3}
              />
              {source === "input" && (
                <Icon
                  as={BsX}
                  boxSize={5}
                  position="absolute"
                  top={-2}
                  right={-1}
                  cursor="pointer"
                  color="gray.400"
                  bg="background"
                  borderRadius="10"
                  borderWidth={1}
                  className="close-icon"
                  display="none"
                  onClick={() => onRemoveFile(index)}
                />
              )}
            </Flex>
          ) : (
            <Flex
              direction="row"
              alignItems="center"
              bg="gray.50"
              p="5px"
              borderRadius="5px"
            >
              {file.type === "application/pdf" ? (
                <Icon
                  as={BsFileEarmarkPdf}
                  boxSize={5}
                  color="red.500"
                  mr={3}
                />
              ) : (
                <Icon as={BsFileEarmark} boxSize={5} color="gray.500" mr={3} />
              )}
              <Flex direction="column" flex="1">
                <Text
                  fontSize="12px"
                  fontWeight="bold"
                  color="highlight.primary"
                >
                  {file.name}
                </Text>
                <Text fontSize="10px" color="gray.500">
                  {file.type.split("/").pop()}
                </Text>
              </Flex>
              {source === "input" && (
                <Icon
                  as={BsX}
                  boxSize={4}
                  cursor="pointer"
                  onClick={() => onRemoveFile(index)}
                  color="gray.400"
                  bg="background"
                  borderRadius="10"
                  borderWidth={1}
                  ml="20px"
                />
              )}
            </Flex>
          )}
        </Flex>
      ))}
    </>
  );
}
