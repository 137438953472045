const HOST_URL = window.location.protocol + "//" + window.location.hostname;
const HOST_NAME = window.location.hostname;

export const environment = {
  // BACKEND_API:
  BACKEND_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : `${HOST_URL}`,

  MOLECULELAKE_DATA_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:5000"
      : HOST_NAME.trim() === "lakesai.vitafluence.com"
      ? "https://mdpp.vitafluence.com"
      : "https://mdps.vitafluence.com",

  STRIPE_PUBLISHABLE_KEY:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "pk_test_51PTJHaP9ouUg1VcitkTtGSPV1R2tix2Ek4QI9AKV6xPK4D7zSU5lVmFmrNRuRmW6N2vy3yFFLVJjy07OuhnUUyn800wdAghs2I"
      : "pk_live_51PYm9qLCHMm6EaV2uTnsWXxwSIPqSwxraPKYRUy4tQ1THs6bruUjfhUfqRjgpgv4MRZMBwd0rZC7LWTKrfz1JbC900eMOcnO1R",

  APP_CLIENT_ID:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "j0ukb4o196taobjckgadpd2vs"
      : HOST_NAME.trim() === "lakesai.vitafluence.com"
      ? "2ooeq3gsq2452hl0j8u6b9320f"
      : "j0ukb4o196taobjckgadpd2vs",

  AUTH_DOMAIN:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "https://auth-dev.vitafluence.com"
      : HOST_NAME.trim() === "lakesai.vitafluence.com"
      ? "https://auth.vitafluence.com"
      : "https://auth-dev.vitafluence.com",

  AUTH_REDIRECT_URI:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000/oauth2/idpresponse"
      : `${HOST_URL}/oauth2/idpresponse`,
};

export const FeedbackParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "feedback_template",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};

export const SubscriptionParams = {
  SERVICE_ID: "service_7wmvk8q",
  TEMPLATE_ID: "template_hby6us9",
  PUBLIC_KEY: "MptCfhc-nceirf57E",
};

export const ContactParams = {
  SERVICE_ID: "service_l267a3e",
  TEMPLATE_ID: "contact_template",
  PUBLIC_KEY: "HgGHRjPm2WVGXxgBq",
};
