import { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import ReadMoreButton from "./ReadMoreButton";
import NoDataAvailable from "./NoDataAvailable";
import MainPanelError from "components/ui/MainPanelError";
import CompoundsTable from "components/compounds/CompoundsTable";

import { CompoundProps } from "models/compounds/CompoundProps";

interface MoleculesProps {
  molecules: CompoundProps[];
  allFetched: boolean;
  allFetchFailed: boolean;
}

// limit nbr of molecules to show to 3 initially
const MAX_SLICE = 3;

function Molecules({ molecules, allFetched, allFetchFailed }: MoleculesProps) {
  // States
  const [showAll, setShowAll] = useState(false);

  const moleculesToShow = showAll ? molecules : molecules?.slice(0, MAX_SLICE);

  // loading
  if (!allFetched)
    return (
      <Box my={6}>
        <Loading message={"Loading molecules..."} />
      </Box>
    );

  // Some molecules are not found; we ignore them and display the rest
  if (allFetchFailed) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading molecules"} />
      </Box>
    );
  }

  return (
    <>
      {moleculesToShow && moleculesToShow?.length > 0 ? (
        <Flex direction={"column"}>
          <CompoundsTable items={moleculesToShow} inChat />

          {/* read more/less */}
          {molecules.length > MAX_SLICE && (
            <ReadMoreButton
              label={
                showAll
                  ? "view less"
                  : `view more (+${molecules?.length - MAX_SLICE})`
              }
              onClick={() => setShowAll(!showAll)}
            />
          )}
        </Flex>
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

export default Molecules;
