import { Flex, Icon, Text } from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";

interface SuccessSendMessageProps {
  text: string;
  onClose?: () => void;
}

export default function SuccessSendMessage({
  text,
  onClose,
}: SuccessSendMessageProps) {
  return (
    <Flex
      direction={"column"}
      gap={6}
      justify={"center"}
      align={"center"}
      w={"full"}
      h={"fit-content"}
      maxW={"80%"}
      mx={"auto"}
      alignSelf={"center"}
      justifySelf={"center"}
      bg={"transparent"}
    >
      <Flex direction={"column"} gap={2} justify={"center"} align={"center"}>
        <Icon as={BiMailSend} boxSize={10} color={"highlight.primary"} />
        <Text color={"gray.700"} fontWeight={"500"}>
          Thank you!
        </Text>
      </Flex>

      <Text fontSize={"14px"} color={"gray.500"} textAlign={"center"}>
        {text}
      </Text>
    </Flex>
  );
}
