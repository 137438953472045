import { Flex, useBreakpointValue, useToast } from "@chakra-ui/react";
import { CustomThickScrollBar } from "components/ui/CustomScrollBar";
import Note, { NoteProps } from "./Note";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";
import { SavedFolderProps } from "models/bookmarks/SavedProps";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";

interface NotesListProps {
  notes: NoteProps[];
}

interface DeleteNoteProps {
  elementType: "NOTES";
  content: {
    elementId: string | undefined;
  };
}

function NotesList({ notes }: NotesListProps) {
  // Hooks
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { savedFolders } = useSelector(selectCurrentSavedData);
  const toast = useToast();

  //Handler
  async function handleDeleteNote({ elementType, content }: DeleteNoteProps) {
    if (!content) return;

    try {
      const newFolders = savedFolders.map((f: SavedFolderProps) => {
        if (f.id === folderId) {
          return {
            ...f,
            notes: f?.notes?.filter((note: { note_id: string }) => {
              return note.note_id !== content.elementId;
            }),
          };
        }

        return f;
      });

      dispatch(setSuccess(newFolders));

      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId,
        elementType,
        content,
      });
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
      console.log(error);
    }
  }

  const notepadHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  const notesStyle = {
    height: `calc(${notepadHeight} - 194px)`,
    width: "100%",
    padding: "0 10px 0 0",
    margin: 0,
  };
  return (
    <CustomThickScrollBar style={notesStyle}>
      <Flex direction={"column"} gap={2}>
        {notes?.map((note: NoteProps) => (
          <Note
            key={note?.note_id}
            {...note}
            onDeleteNote={() =>
              handleDeleteNote({
                elementType: "NOTES",
                content: { elementId: note?.note_id },
              })
            }
          />
        ))}
      </Flex>
    </CustomThickScrollBar>
  );
}

export default NotesList;
