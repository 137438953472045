import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Flex,
  Textarea,
  FormControl,
  InputGroup,
  useToast,
  Icon,
  Text,
} from "@chakra-ui/react";
import { BsSendFill } from "react-icons/bs";
import AttachFile from "./AttachFile";
import { ChatbotContext } from "views/chatbot";
import TextFieldFiles from "./TextFieldFiles";
import AudioRecording from "./AudioRecording";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

const MAX_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB

export default function TextField({
  onSendQuestion,
}: {
  onSendQuestion: (
    s: string,
    files: File[],
    audio?: Blob,
    type?: string
  ) => void;
}) {
  const [question, setQuestion] = useState("");
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [recording, setRecording] = useState(false);
  const { user } = useSelector(selectCurrentAuthData);
  const { setFileAttached } = useContext(ChatbotContext);
  const toast = useToast();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const handleSend = () => {
    console.log(recordedAudio, "recorded audio");
    if (question.trim() || attachedFiles.length || recordedAudio) {
      const type = recordedAudio !== null ? "audio" : "text";
      onSendQuestion(
        question.trim(),
        attachedFiles,
        recordedAudio || undefined,
        type
      );
      setQuestion("");
      setAttachedFiles([]);
      setRecordedAudio(null);
      setFileAttached(false);
    }
    setRecording(false);
  };

  const handleFileSelect = (files: File[] | null) => {
    if (!files) return;
    const validFiles = files.filter((file) => file.size <= MAX_SIZE_BYTES);
    if (validFiles.length < files.length) {
      toast({
        description: `Some files exceed the 20 MB limit`,
        status: "error",
        position: "top-right",
      });
    }
    setAttachedFiles(validFiles);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    setFileAttached(!!attachedFiles.length);
  }, [attachedFiles, setFileAttached]);

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [mediaRecorder]);
  return (
    <Flex
      direction="column"
      gap={1}
      w={{ lg: "700px", xl: "900px" }}
      maxW="90%"
      mx="auto"
      align="center"
    >
      <FormControl
        position="relative"
        w="100%"
        bg="background"
        borderRadius="16px"
        borderWidth={1}
        borderColor="gray.100"
      >
        {attachedFiles.length > 0 && (
          <TextFieldFiles
            files={attachedFiles}
            onRemoveFile={(i) =>
              setAttachedFiles((prev) => prev.filter((_, idx) => idx !== i))
            }
            source="input"
          />
        )}
        <InputGroup minHeight="60px" maxHeight="60px">
          {/* {recordedAudio === null && ( */}
          <AttachFile
            onFileSelect={handleFileSelect}
            accept="application/pdf,image/jpeg,image/jpg,image/gif,image/png,image/webp"
            user={user}
          />
          {/* )} */}
          {recordedAudio === null && (
            <Textarea
              value={question}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setQuestion(e.target.value.replace(/(\r\n|\n|\r)/gm, ""))
              }
              onKeyDown={handleKeyDown}
              placeholder={recording ? "Sai Listening" : "Ask Sai..."}
              borderRadius="16px"
              bg="background"
              border="none"
              resize="none"
              minHeight="60px"
              maxHeight="60px"
              pt={"20px"}
              width={"85%"}
              _focus={{
                borderColor: "transparent",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
          )}
          <AudioRecording
            setRecordedAudio={setRecordedAudio}
            recording={recording}
            setRecording={setRecording}
            user={user}
            recordedAudio={recordedAudio}
            mediaRecorder={mediaRecorder}
            setMediaRecorder={setMediaRecorder}
          />
          {!recording && (
            <Icon
              as={BsSendFill}
              position="absolute"
              top="50%"
              right={4}
              transform="translateY(-50%)"
              boxSize={4}
              color="primary.300"
              cursor="pointer"
              onClick={handleSend}
              _hover={{ color: "highlight.primary" }}
            />
          )}
        </InputGroup>
      </FormControl>
      <Text
        fontSize={"12px"}
        color={"gray.500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
      >
        GenAI can make mistakes. Double check important information.
      </Text>
    </Flex>
  );
}
