import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";

import BreadcrumbHome from "components/ui/BreadcrumbHome";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { SourceProps } from "../helpers";

interface CoreBreadcrumbProps {
  layer: string;
  source: SourceProps | undefined;
  toolName: string;
  name: string;
}

function CoreBreadcrumb({
  layer,
  source,
  name,
  toolName,
}: CoreBreadcrumbProps) {
  // Navigation
  const navigate = useNavigate();

  // Handlers
  function handleGoToIndex() {
    navigate("/data/core");
  }

  function handleGoToOpenLayer() {
    if (layer) {
      navigate(`/data/core/${layer}`);
    }
  }

  function handleGoToOpenCoreSource() {
    if (layer && !!source && !!source?.key) {
      const l_coreSourceKey = source?.key?.toLowerCase();
      navigate(`/data/core/${layer}/data/${l_coreSourceKey}`);
    }
  }

  return (
    <Breadcrumb
      spacing={1}
      separator={<ChevronRightIcon color="gray.500" />}
      fontSize={"14px"}
      w={"600px"}
    >
      {/* Index */}
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleGoToIndex} lineHeight={1} p={1.5}>
          <BreadcrumbHome inCore />
        </BreadcrumbLink>
      </BreadcrumbItem>

      {/* layer ID */}
      {layer && (
        <BreadcrumbItem
          onClick={handleGoToOpenLayer}
          color={layer && (!source || !toolName) ? "primary.400" : "gray.500"}
        >
          <BreadcrumbLink
            fontWeight={source || toolName ? "400" : "500"}
            pointerEvents={source || toolName ? "auto" : "none"}
            _hover={{
              textDecoration:
                layer && !source && !toolName ? "none" : "underline",
            }}
          >
            {layer?.length > 0 ? layer : "Unknown Layer"}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Source */}
      {source && (
        <BreadcrumbItem
          onClick={handleGoToOpenCoreSource}
          color={source && !name ? "primary.400" : "gray.500"}
        >
          <BreadcrumbLink
            fontWeight={name ? "400" : "500"}
            pointerEvents={name ? "auto" : "none"}
            _hover={{ textDecoration: source && !name ? "none" : "underline" }}
          >
            <Text textTransform={"capitalize"}>
              {source?.key?.toLowerCase()}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Tool */}
      {toolName && (
        <BreadcrumbItem
          color={"primary.400"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <BreadcrumbLink
            fontWeight={"500"}
            pointerEvents={"none"}
            _hover={{ textDecoration: "none" }}
            w={"100%"}
          >
            <Text
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              textTransform={"capitalize"}
            >
              {toolName?.toLowerCase()}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {/* Source item name */}
      {name && (
        <BreadcrumbItem
          isCurrentPage
          color={"primary.400"}
          fontWeight={"500"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <BreadcrumbLink w={"100%"}>
            <Text
              fontSize={"small"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              textTransform={"uppercase"}
            >
              {name}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default CoreBreadcrumb;
