import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { TeamMemberActivityProps } from "models/posts/PostProps";

interface DataProps {
    teamActivities: TeamMemberActivityProps[]
}

const tableStyle = {
    height: "94%",
    width: "100%",
    padding: "0",
};

const ActivityTable = ({ teamActivities }: DataProps) => {

    const headers = ["No.", "Activity", "By", "Date"];

    const renderHeader = (text: string, index: number) => {
        return (
            <Th
                key={"table-header" + index}
                py={2}
                px={2}
                textAlign={"left"}
                position="sticky"
                top={0}
                zIndex={1}
                bg="gray.100"
                borderLeftRadius={index === 0 ? "md" : "none"}
                borderRightRadius={index === headers.length - 1 ? "md" : "none"}
                borderRight={index !== headers.length - 1 ? "1px solid" : "none"}
                borderRightColor={"gray.200"}
            >
                {text}
            </Th>
        );
    };

    const renderRow = (data: TeamMemberActivityProps, index: number) => {
        return (
            <Tr
                key={"table-row" + index}
                py={5}
                transition="background 0.3s ease"
                _hover={{ cursor: "pointer", bg: "gray.100" }}
            >
                <Td
                    key={'number' + index}
                    borderColor={"gray.100"}
                    p={1.5}
                    isTruncated
                >
                    {(index + 1)}.
                </Td>                
                <Td
                    key={'activity' + index}
                    borderColor={"gray.100"}
                    p={1.5}
                    isTruncated
                >
                    {data.activity}
                </Td>
                <Td
                    key={'given_name_family_name' + data.given_name + data.family_name + index}
                    borderColor={"gray.100"}
                    p={1.5}
                    isTruncated
                >
                    {data.given_name} {data.family_name}
                </Td>                
                <Td
                    key={'date' + index}
                    borderColor={"gray.100"}
                    p={1.5}
                    isTruncated
                >
                    {new Date(data.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true
                    })}
                </Td>
            </Tr>
        );
    };

    return (
        <TableContainer height="88%" style={{ position: "relative" }}>
            <CustomScrollBar style={tableStyle}>
                <Table variant="simple" size="sm">
                    {
                        teamActivities?.length > 0 ?
                            <>
                                <Thead>
                                    <Tr>{headers.map((text, index) => renderHeader(text, index))}</Tr>
                                </Thead>
                                <Tbody>{teamActivities?.map((row: any, index: number) => renderRow(row, index))}</Tbody>
                            </>
                            :
                            <Text color={"gray.500"} marginTop={20} textAlign={"center"}>There is no activity yet for you.</Text>
                    }
                </Table>
            </CustomScrollBar>
        </TableContainer>
    );
};

export default ActivityTable;
