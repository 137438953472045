import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { BsFileEarmarkPdf, BsFileEarmark } from "react-icons/bs";

interface FileProps {
  file: File | string | Blob;
  source: string;
}

export default function HumanMessageFiles({ file, source }: FileProps) {
  let fileType: string | undefined;
  let fileSrc: string | undefined;
  let filename: string | undefined;
  // Type guard functions
  const isFile = (file: any): file is File => file instanceof File;
  const isBlob = (file: any): file is Blob => file instanceof Blob;

  // Determine file type and source
  if (typeof file === "string") {
    fileType = file.split(".").pop()?.toLowerCase();
    fileSrc = file;
    filename = file;
  } else if (isFile(file)) {
    fileType = file.name.split(".").pop()?.toLowerCase();
    fileSrc = URL.createObjectURL(file);
    filename = file.name;
  } else if (isBlob(file)) {
    fileType = file.type.split("/").pop()?.toLowerCase();
    fileSrc = URL.createObjectURL(file);
    filename = "audio file";
  } else {
    return null;
  }

  return (
    <>
      {fileType?.match(/(jpg|jpeg|png|gif|webp)/) && (
        <Box>
          <Image
            src={fileSrc}
            alt="File preview"
            boxSize="150px"
            objectFit="scale-down"
            borderRadius="10px"
          />
        </Box>
      )}
      {fileType === "pdf" && (
        <Flex
          direction="row"
          alignItems="center"
          borderWidth="0.5px"
          borderColor="gray.200"
          p="5px"
          borderRadius="10px"
        >
          <Icon as={BsFileEarmarkPdf} boxSize={7} mr={3} color="red.500" />
          <Box>
            <Text fontSize="14px" fontWeight="500" color="gray.800">
              {filename}
            </Text>
            <Text fontSize="12px" fontWeight="bold" color="gray.400">
              PDF File
            </Text>
          </Box>
        </Flex>
      )}
      {fileType === "wav" && (
        <Flex p={0} height="20px" mt="2px" ml="2px" alignItems="center">
          <Box>
            <audio controls>
              <source src={fileSrc} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        </Flex>
      )}
      {!["pdf", "jpg", "jpeg", "png", "gif", "webp", "wav"].includes(
        fileType || ""
      ) && (
        <Flex
          alignItems="center"
          direction="row"
          bg="gray.50"
          p="5px"
          borderRadius="5px"
        >
          <Icon as={BsFileEarmark} boxSize={7} color="gray.800" mr={3} />
          <Box>
            <Text fontSize="14px" fontWeight="500" color="gray.700">
              {filename}
            </Text>
            <Text fontSize="12px" fontWeight="bold" color="gray.400">
              {fileType?.toUpperCase() || "Unknown"} File
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
}
