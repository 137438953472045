import { Flex, Text, Icon, useRadioGroup } from "@chakra-ui/react";
import { IconType } from "react-icons";
import FeedbackType from "./FeedbackType";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { HiExclamationCircle, HiOutlineExclamationCircle } from "react-icons/hi";
import { RiLightbulbLine, RiLightbulbFill } from "react-icons/ri";

interface Type {
  name: string;
  outline: IconType;
  solid: IconType;
}

const types: Type[] = [
  { name: "Compliment", outline: FaRegHeart, solid: FaHeart },
  { name: "Suggestion", outline: RiLightbulbLine, solid: RiLightbulbFill },
  {
    name: "Problem",
    outline: HiOutlineExclamationCircle,
    solid: HiExclamationCircle,
  },
];

interface FeedbackTypesProps {
  selectedType: string;
  setSelectedType: (type: string) => void;
}

export default function FeedbackTypes({ selectedType, setSelectedType }: FeedbackTypesProps) {
  const { getRadioProps } = useRadioGroup({
    name: "type",
    defaultValue: selectedType,
    onChange: setSelectedType
  });

  return (
    <Flex direction="column" gap={3}>
      <Text
        fontSize="14px"
        color={"gray.600"}
        lineHeight="1.3"
        fontWeight={"500"}
      >
        What type of feedback would you like to provide?
      </Text>

      <Flex direction={"column"} gap={3}>
        {types.map((type: Type) => {
          const radio = getRadioProps({ value: type.name });
          const { name, isChecked } = radio;

          const icon: IconType = isChecked ? type.solid : type.outline;

          return (
            <FeedbackType key={type.name} {...radio} name={name}>
              <Icon as={icon} boxSize={4} />
              {type.name}
            </FeedbackType>
          );
        })}
      </Flex>
    </Flex>
  );
}
