import { ChangeEvent, useContext, useEffect, useRef } from "react";
import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { BsPaperclip } from "react-icons/bs";
import { ChatbotContext } from "views/chatbot";

interface FileUploadIconProps {
  onFileSelect: (file: File[] | null) => void;
  accept: string;
  user: any;
}

export default function AttachFile({
  onFileSelect,
  accept,
  user,
}: FileUploadIconProps) {
  const { setFileAttached } = useContext(ChatbotContext);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // Handle file selection
  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const files = Array.from(e.target.files || []);
    onFileSelect(files);
    setFileAttached(true);

    // Reset the file input to allow re-selection of the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  // Trigger the file input click
  function handleAttachClick() {
    fileInputRef.current?.click();
  }

  useEffect(() => {}, [setFileAttached]);

  return (
    <form id="form-file-upload">
      <Tooltip
        label={
          user.user_type !== "teams" &&
          user.user_type !== "premium" &&
          user.user_type !== "basic"
            ? "Upgrade to teams, basic or premium plan to upload files"
            : "Attach a file"
        }
        placement="bottom"
        bg={"gray.900"}
        color={"gray.100"}
        hasArrow
        py={2}
        px={3}
        m={2}
        maxW={[null, null, null, "200px", "220px", "240px"]}
        borderRadius={"6px"}
        fontSize={"12px"}
        boxShadow={"none"}
        arrowSize={8}
      >
        <Flex
          direction="column"
          justifyContent={"center"}
          height={"60px"}
          gap={3}
          alignContent={"center"}
          ml={"20px"}
        >
          <Icon
            as={BsPaperclip}
            cursor="pointer"
            boxSize={5}
            color="primary.300"
            onClick={
              user.user_type === "teams" ||
              user.user_type === "premium" ||
              user.user_type === "basic"
                ? handleAttachClick
                : undefined
            }
            _hover={{ color: "highlight.primary" }}
            position={"relative"}
            zIndex={99}
            alignContent={"center"}
          />

          {/* Hidden file input */}
          <input
            id="file-input"
            type="file"
            // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            accept={accept}
            multiple={false}
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </Flex>
      </Tooltip>
    </form>
  );
}
