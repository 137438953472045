import { MutableRefObject, useEffect, useRef } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";

import {
  SavedAssayProps,
  SavedBotMessagesProps,
  SavedCompoundProps,
  SavedFolderProps,
  SavedLiteratureProps,
} from "models/bookmarks/SavedProps";
import { usePDF } from "@react-pdf/renderer";
import { soon } from "assets/bookmarks/reports/icons";
import DownloadReportButton from "./DownloadReportButton";
import BookmarkToExport from "../folder/toExport/BookmarkToExport";

import { hexToRgba } from "utils/helpers";
import { TbSparkles } from "react-icons/tb";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

interface ReportsProps {
  folder: SavedFolderProps | undefined;
  isOpen: boolean;
  onClose: () => void;
}

function Reports({ folder, isOpen, onClose }: ReportsProps) {
  // Hooks
  const btnRef: MutableRefObject<HTMLElement | null> = useRef(null);

  // Theme
  const { colors } = useTheme();

  const reportsHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  let molecules: SavedCompoundProps[] = [];
  let assays: SavedAssayProps[] = [];
  let literature: SavedLiteratureProps[] = [];
  let botMessages: SavedBotMessagesProps[] = [];

  if (folder) {
    molecules = folder?.compounds ?? [];
    assays = folder?.assays ?? [];
    literature = folder?.literatures ?? [];
    botMessages = folder?.botMessages ?? [];
  }

  // if folder has no content
  const isEmpty =
    !assays?.length &&
    !molecules?.length &&
    !literature?.length &&
    !botMessages?.length;

  // PDF document instance
  const [instance, updateInstance] = usePDF({
    document: (
      <BookmarkToExport
        assays={assays}
        molecules={molecules}
        literature={literature}
        botMessages={botMessages}
        folderName={folder?.name ?? ""}
      />
    ),
  });

  const { loading: loadingReport } = instance;

  useEffect(() => {
    if (isOpen) {
      updateInstance(
        <BookmarkToExport
          assays={assays}
          molecules={molecules}
          literature={literature}
          botMessages={botMessages}
          folderName={folder?.name ?? ""}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {folder ? (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay
            backdropFilter={"blur(3.5px)"}
            bg={"rgba(0,0,0,0.1)"}
          />

          <DrawerContent
            bg={"background"}
            h={reportsHeight}
            w={"320px"}
            maxW={"320px"}
            borderRadius={"12px 0 0 12px"}
            p={0}
            alignSelf={"center"}
            borderColor={"gray.100"}
            borderWidth={1}
            boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
          >
            <DrawerCloseButton size={"sm"} color={"gray.400"} />

            <DrawerHeader
              p={3}
              borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
              borderBottomWidth={1}
            >
              <Flex align={"center"} gap={2} color={"gray.600"}>
                <Icon as={HiOutlineDocumentDuplicate} boxSize={4} />
                <Text fontSize={"14px"}>Bookmark reports</Text>
              </Flex>
            </DrawerHeader>

            <DrawerBody p={3} display={"flex"} flexDir={"column"} gap={4}>
              <Flex
                align={"center"}
                justify={"center"}
                gap={2}
                direction={"column"}
                h={"100%"}
              >
                {/* new note illustration */}
                <Image
                  src={soon}
                  alt={"Reports History - Coming Soon"}
                  w={"40%"}
                  objectFit={"cover"}
                />

                <Text
                  fontSize={"14px"}
                  color={"gray.600"}
                  fontFamily={"Poppins, sans-serif"}
                  textAlign={"center"}
                  width={"88%"}
                  mt={6}
                >
                  Download your first report (as PDF) to view the reports
                  history here
                </Text>
                <Flex color={"highlight.primary"} align={"center"} gap={1}>
                  <Icon as={TbSparkles} boxSize={5} />
                  <Text fontWeight={"500"} fontSize={"16px"}>
                   Updates coming soon!
                  </Text>
                </Flex>
              </Flex>
            </DrawerBody>

            <DownloadReportButton
              url={instance?.url ?? ""}
              isDisabled={isEmpty || loadingReport}
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}

export default Reports;
