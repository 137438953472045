import { ChangeEvent } from "react";
import { AxiosInstance } from "axios";

import { environment } from "environments";
import { FieldProps } from "./SubmitFeedbackForm";

export const sendEmail = async (
  e: React.FormEvent,
  message: string,
  files: FieldProps<File[]>,
  user: { email?: string },
  fullName: string,
  setSubmitting: (submitting: boolean) => void,
  setSent: (sent: boolean) => void,
  setMessage: (message: string) => void,
  setFileUpload: (fileUpload: FieldProps<File[]>) => void,
  toast: (options: any) => void,
  axiosPrivate: AxiosInstance,
  MAX_TOTAL_SIZE: number,
  selectedType: string
) => {
  e.preventDefault();
  setSubmitting(true);

  if (!message.trim()) {
    setSubmitting(false);
    return;
  }

  const totalSize =
    files.value.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024); // size in MB

  if (totalSize > MAX_TOTAL_SIZE) {
    setSubmitting(false);
    return;
  }

  const data = new FormData();
  data.append("message", String(message));
  files.value.forEach((file) => data.append("files", file));
  data.append("email", user?.email || "");
  data.append("feedback_type", selectedType);
  data.append("name", fullName);

  try {
    await axiosPrivate.post(
      `${environment.BACKEND_API}/api/send_feedback`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setSent(true);
  } catch (err: any) {
    toast({
      description:
        err.response?.data?.message ||
        "Failed to send feedback. Please try again.",
      status: err.response?.status === 400 ? "error" : "info",
      position: "top-right",
    });
  } finally {
    setSubmitting(false);
  }

  (e.target as HTMLFormElement).reset();
  setMessage("");
  setFileUpload({ value: [], invalid: false, error: "" });
};

export const handleRemoveFile = (
  fileName: string,
  setFileUpload: (
    fileUpload: (prev: FieldProps<File[]>) => FieldProps<File[]>
  ) => void
) => {
  setFileUpload((prev: FieldProps<File[]>) => {
    const updatedFiles = prev.value.filter((file) => file.name !== fileName);
    return {
      ...prev,
      value: updatedFiles,
      invalid: false,
      error: "",
    };
  });
};

export const handleFileChange = (
  e: ChangeEvent<HTMLInputElement>,
  handleFileValidation: (filesList: FileList | null) => void
) => {
  handleFileValidation(e.target.files);
};

export const handleFileValidation = (
  filesList: FileList | null,
  MAX_FILE_COUNT: number,
  validTypes: string[],
  currentFiles: File[],
  setFileUpload: (fileUpload: FieldProps<File[]>) => void
) => {
  if (!filesList) {
    return;
  }

  const newFiles = Array.from(filesList);
  const existingFiles = currentFiles;

  const filteredFiles = newFiles.filter((file) =>
    validTypes.includes(file.type)
  );
  const filesToProcess = filteredFiles.slice(
    0,
    MAX_FILE_COUNT - existingFiles.length
  );

  let error = "";
  let validFiles: File[] = [];

  for (const file of filesToProcess) {
    if (existingFiles.some((existingFile) => existingFile.name === file.name)) {
      error = "File already exists";
      continue;
    }
    validFiles.push(file);
  }

  if (filteredFiles.length > MAX_FILE_COUNT) {
    error =
      "Max 10 files allowed per upload! We have picked the first 10 files.";
  }

  const updatedFiles = [...existingFiles, ...validFiles];

  setFileUpload({
    value: updatedFiles,
    invalid: !!error,
    error,
  });
};
