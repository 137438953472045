import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Switch,
  Text,
} from "@chakra-ui/react";

import { selectCurrentAuthData, User } from "redux/features/auth/authSlice";
import PlanOffers from "components/home/pricing/PlanOffers";
import { PRICING_PLANS } from "views/home/helpers";

import { FiCornerLeftDown } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import Bg from "assets/home/pricing/bg.svg";

interface SwitchBillPlanProps {
  isAnnual: boolean;
  switchPlan: () => void;
}

interface Props {
  inHome?: boolean;
}

interface UserProps {
  user: User;
}

export default function Pricing({ inHome }: Props) {
  // States
  const [isAnnual, setIsAnnual] = useState(false);

  // Hooks
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  let plans = PRICING_PLANS?.map((plan: any) => ({
    ...plan,
    promotion_price: Math.round(
      plan.plan_price - (plan.plan_price * plan.discount) / 100
    ),
  }));

  // Handlers
  const handleToggle = () => {
    setIsAnnual((a) => !a);
  };

  return (
    <Flex
      id="pricing"
      bg={isLight ? "background" : "dark.400"}
      h={"fit-content"}
      minH={inHome ? "unset" : "100vh"}
      backgroundImage={isLight ? Bg : undefined}
      backgroundRepeat={"no-repeat"}
      backgroundSize={isLight ? "min(80%, 900px)" : "unset"}
      backgroundPosition={isLight ? "bottom right" : "unset"}
    >
      <Flex
        position={"relative"}
        direction={"column"}
        maxW={"1440px"}
        mx={"auto"}
        w={"95%"}
        py={"80px"}
        alignItems={"center"}
        justifyContent={"center"}
        h={"100%"}
        gap={10}
      >
        {/* Heading */}
        <Flex direction={"column"} textAlign={"center"} gap={4}>
          <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
            {"Lakesai Pricing".toUpperCase()}
          </Text>
          <Heading size={"lg"} fontFamily={"monospace"}>
            {"Flexible Plans for Everyone"}
          </Heading>
        </Flex>

        {/* plans grid */}
        <Flex
          direction={"column"}
          gap={10}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* Bill Monthly/Annually */}
          <SwitchBillPlan isAnnual={isAnnual} switchPlan={handleToggle} />

          <SimpleGrid
            maxW={{
              base: "95%",
              sm: "420px",
              md: "90%",
              lg: "824px",
              xl: "100%",
            }}
            mx={"auto"}
            columns={{ base: 1, md: 2, xl: 4 }}
            spacing={6}
          >
            {plans?.map((plans: any) => (
              <PlanOffers
                isAnnual={isAnnual}
                key={plans.plan_name}
                data={plans}
              />
            ))}
          </SimpleGrid>
        </Flex>

        {!inHome && <GoBack user={user} />}
      </Flex>
    </Flex>
  );
}

function SwitchBillPlan({ isAnnual, switchPlan }: SwitchBillPlanProps) {
  return (
    <Flex
      textAlign="center"
      gap={4}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Text
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        fontSize={{ lg: "12px", xl: "14px" }}
        color={!isAnnual ? "secondary.700" : "gray.300"}
      >
        Bill Monthly
      </Text>

      <Switch
        id="billing-switch"
        isChecked={isAnnual}
        onChange={switchPlan}
        size={"sm"}
      />
      <Flex position={"relative"} w={"fit-content"}>
        <Text
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          fontSize={{ lg: "12px", xl: "14px" }}
          color={isAnnual ? "secondary.700" : "gray.300"}
        >
          Bill Annually
        </Text>

        <Flex
          gap={2}
          align={"center"}
          color={"highlight.primary"}
          position={"absolute"}
          right={{ base: "100%", sm: "0" }}
          w={"120px"}
          transform={"translateX(70%)"}
          top={{ base: "30px", sm: "-20px" }}
          direction={{ base: "row-reverse", sm: "row" }}
        >
          <Icon
            as={FiCornerLeftDown}
            boxSize={"14px"}
            alignSelf={{ base: "flex-start", sm: "flex-end" }}
            transform={{ base: "rotate(180deg)", sm: "rotate(0deg)" }}
          />
          <Text fontWeight={"500"} fontSize={"12px"} lineHeight={1.6}>
            Save 2 months
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

function GoBack({ user }: UserProps) {
  // Hooks
  const navigate = useNavigate();

  return (
    <Flex gap={8} textAlign="center" align={"center"} justify={"center"}>
      <Button
        size="sm"
        fontSize={{ lg: "12px", xl: "14px" }}
        onClick={() => navigate("/data")}
        colorScheme={"blue"}
        borderRadius={"20px"}
        h={"fit-content"}
        px={5}
        py={2.5}
      >
        Go Back
      </Button>

      {user?.user_type === "free" && (
        <Flex
          align={"center"}
          justify={"center"}
          gap={2}
          color={"secondary.500"}
          fontSize={{ lg: "12px", xl: "14px" }}
          fontWeight={"500"}
        >
          <Link color={"secondary.500"} onClick={() => navigate("/data")}>
            Continue with <b>free plan</b>
          </Link>
          <FaArrowRight />
        </Flex>
      )}
    </Flex>
  );
}
