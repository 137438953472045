import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useLayersAPI = () => {
    const axiosPrivate = useAxiosPrivate();

    const getLayers = async () => {
        const response = await axiosPrivate.get(`/api/layers`);
        return response.data;
    };

    return {
        getLayers        
    };
};
