import { useEffect } from "react";
import { Box, Flex, Icon, Tooltip, useTheme } from "@chakra-ui/react";
import { FaMicrophone } from "react-icons/fa6";
import { hexToRgba } from "utils/helpers";
import { BsX } from "react-icons/bs";
import styled from "styled-components";
import { keyframes } from "@emotion/react";

interface AudioRecordingProps {
  setRecordedAudio: (recordedAudio: Blob | null) => void;
  recording: boolean;
  setRecording: (val: boolean) => void;
  user: any;
  recordedAudio: Blob | null;
  mediaRecorder: MediaRecorder | null;
  setMediaRecorder: (mediaRecorder: MediaRecorder) => void;
}

const pulseBorder = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledAudio = styled.audio<{ bgColor: string }>`
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-panel,
  &::-webkit-media-controls-volume-slider,
  &::-webkit-media-controls-current-time-display,
  &::-webkit-media-controls-time-remaining-display,
  &::-webkit-media-controls-fullscreen-button {
    background-color: ${(props) => props.bgColor} !important;
  }
`;
export default function AudioRecording({
  setRecordedAudio,
  recording,
  setRecording,
  user,
  recordedAudio,
  mediaRecorder,
  setMediaRecorder,
}: AudioRecordingProps) {
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgColor = isLight ? "background" : `#0B2E3D`;

  // Start recording logic
  const handleStartRecording = async () => {
    if (recordedAudio) {
      // Optionally, you can show a message or alert
      console.log("A recording is already in progress or has been completed.");
      return;
    }
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);

    recorder.ondataavailable = (e) => {
      const audioFile = new File([e.data], `audio_${Date.now()}.wav`, {
        type: e.data.type,
      });
      setRecordedAudio(audioFile);
    };

    recorder.start();
    setMediaRecorder(recorder);
    setRecording(true);
  };

  // Stop recording logic
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [mediaRecorder]);

  return (
    <>
      {recordedAudio !== null && (
        <Flex p={0} height={"20px"} mt={"2px"} ml={"2px"} zIndex={1000}>
          <Box>
            <StyledAudio controls bgColor={bgColor}>
              <source
                src={URL.createObjectURL(recordedAudio)}
                type="audio/wav"
                style={{ backgroundColor: "red" }}
              />
              Your browser does not support the audio element.
            </StyledAudio>
          </Box>
          <Icon
            as={BsX}
            boxSize={5}
            top={-2}
            right={-1}
            cursor="pointer"
            color="gray.400"
            bg="background"
            borderRadius="10"
            borderWidth={1}
            className="close-icon"
            onClick={() => setRecordedAudio(null)}
          />
        </Flex>
      )}
      <Tooltip
        label={
          user.user_type !== "teams" &&
          user.user_type !== "premium" &&
          user.user_type !== "basic"
            ? "Upgrade to teams, basic or premium plan"
            : recordedAudio
            ? "Removed the attached audio first to record new one"
            : recording
            ? "Recording... click to stop recording"
            : "Record audio"
        }
        placement="bottom"
        bg={"gray.900"}
        color={"gray.100"}
        hasArrow
        py={2}
        px={3}
        borderRadius={"6px"}
      >
        <Box
          position={"absolute"}
          justifyContent="center"
          display={"flex"}
          alignItems={"center"}
          top={recording ? "15%" : "35%"}
          right={recording ? 2 : 16}
          width={!recording ? "fit-content" : "40px"}
          height={!recording ? "fit-content" : "40px"}
          borderRadius="50%"
          border={`2px solid ${
            recording ? hexToRgba(colors.red[500], 0.9) : "transparent"
          }`}
          animation={recording ? `${pulseBorder} 1s infinite` : "none"}
          background={
            recording ? hexToRgba(colors.red[500], 0.2) : "transparent"
          }
        >
          <Icon
            as={FaMicrophone}
            cursor={"pointer"}
            boxSize={4}
            color={recording ? "red.500" : "primary.300"}
            onClick={recording ? stopRecording : handleStartRecording}
            _hover={{ color: recording ? "red.500" : "highlight.primary" }}
          />
        </Box>
      </Tooltip>
    </>
  );
}
