import React, { ReactNode, ChangeEvent, DragEvent } from 'react';
import { InputGroup, Button, ButtonProps, useTheme, Input, FormErrorMessage, FormControl, Text, Box } from '@chakra-ui/react';
import { ImAttachment } from 'react-icons/im';
import { hexToRgba } from "utils/helpers";

type UploadProps = {
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  accept: string;
  error: string
};

const AttachFileArea = ({
  handleFileChange,
  onButtonClick,
  accept,
  error,
  
}: UploadProps) => {
  const { colors } = useTheme();


  return (
    <form id="form-file-upload">
        <FormControl isRequired id="uploadFile" w="100%" mt="14px" textAlign={'center'}>
          <InputGroup id="file-upload" justifyContent={'center'} width={'fit-content'} justifyItems={'left'} color={'gray.700'}>
            <Input
              type="file"
              name="file-uploader-field"
              id="file-uploader-field"
              multiple={true}
              accept={accept}
              hidden={false}
              onChange={handleFileChange}
              style={{
                width: "100%",
                height: '50px',
                opacity: 0,
                position: 'absolute',
                top: 0,
                zIndex: 1,
                cursor: 'pointer',
              }}
            />
            <Button
              zIndex={0}
              backgroundColor={hexToRgba(colors.gray[300], 0.4)}
              onClick={onButtonClick}
              leftIcon={<ImAttachment color={colors.gray[500]} />}
              color={colors.gray[500]}
              size={'xs'}
              fontWeight={'500'}
            >
              Choose files
            </Button>

            <Text ml={'15px'} alignContent={'center'} color={colors.gray[500]} fontSize={'14px'}>Attach a file</Text>
          </InputGroup>
          <Text textAlign={'center'} alignSelf={'center'} color={'red.500'} mt={'20px'} fontSize={'14px'}>{error}</Text>
        </FormControl>
    </form>
  );
};

export default AttachFileArea;
