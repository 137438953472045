import { environment } from "../environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useStripeAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const createCheckoutSession = async (payload: {
    username?: string;
    email?: string;
    plan: string;
    is_yearly: boolean;
    quantity?: number;
    payment_mode: string;
  }) => {
    const url = `${environment.BACKEND_API}/payments/create_checkout_session`;
    const response = await axiosPrivate.post(url, payload);
    return response.data;
  };

  const cancelSubscription = async () => {
    const url = `${environment.BACKEND_API}/payments/cancel_subscription`;
    const response = await axiosPrivate.post(url);
    return response.data;
  };

  const requestTeamsPackage = async (payload: {
    members: number;
    name?: string;
    email?: string;
    lmic?: string;
    message?: string;
  }) => {
    const url = `${environment.BACKEND_API}/payments/request_teams`;
    const response = await axiosPrivate.post(url, payload);
    return response.data;
  };

  const getUserInvoices = async () => {
    const url = `${environment.BACKEND_API}/payments/invoices`;
    const response = await axiosPrivate.get(url);
    return response.data;
  };

  return { createCheckoutSession, cancelSubscription, requestTeamsPackage, getUserInvoices };
};
